import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { addMonths, endOfMonth, format, startOfMonth, subMonths } from "date-fns";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";

import {
  DescriptionOutlined,
  FormatAlignJustifyRounded,
  InboxOutlined,
  PaymentOutlined,
  PersonOutlineOutlined,
  SellOutlined,
} from "@mui/icons-material";
import Artists from "./Tabs/Artists";
import Orders from "./Tabs/Orders";
import useAdmin from "./useAdmin";
import Payments from "./Tabs/Payments";
import Products from "./Tabs/Products";
import Invoices from "./Tabs/Invoices";
import Extras from "./Tabs/Extras";
import SideBar from "./SideBar";
import { DateRange } from "@mui/x-date-pickers-pro";

export type OrderFilters = {
  dateRange: (string | null)[];
  vendorId?: string;
};

const tabs = [
  { value: "artists", label: "Artists", icon: <PersonOutlineOutlined /> },
  { value: "products", label: "Products", icon: <SellOutlined /> },
  { value: "orders", label: "Orders", icon: <InboxOutlined /> },
  { value: "payments", label: "Payments", icon: <PaymentOutlined /> },
  { value: "invoices", label: "Invoices", icon: <DescriptionOutlined /> },
  { value: "extras", label: "Extras", icon: <FormatAlignJustifyRounded /> },
];

const Admin = () => {
  const { hash } = useLocation();
  const tab = hash.replace("#", "");
  const [selectedTab, setSelectedTab] = useState<string>(tab || tabs[0].value);
  const { users, searchedUsers, fetchUsers, loading, artistSearchValue, setArtistSearchValue } = useAdmin();
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange<string>>([null, null]);
  const [month, setMonth] = useState(startOfMonth(new Date()));
  const [selectedTimeRange, setSelectedTimeRange] = useState<"month" | "range">("month");
  const [timeRangeQuery, setTimeRangeQuery] = useState(
    `created_at:>=${format(month, "yyyy-MM-dd")} created_at:<=${format(endOfMonth(month), "yyyy-MM-dd")}`
  );

  const onTabChange = (tab: number | string) => {
    window.location.hash = tab as string;
    setSelectedTab(tab as string);
  };

  const getUsers = async () => {
    setLoadingUsers(true);
    await fetchUsers();
    setLoadingUsers(false);
  };

  useEffect(() => {
    if (!users && !loadingUsers) {
      getUsers();
    }
  });

  const goToPreviousMonth = () => {
    const prevMonth = subMonths(month, 1);
    setMonth(prevMonth);
  };

  const goToNextMonth = () => {
    const nextMonth = addMonths(month, 1);
    setMonth(nextMonth);
  };

  useEffect(() => {
    if (selectedTimeRange === "month") {
      setTimeRangeQuery(`created_at:>=${format(month, "yyyy-MM-dd")} created_at:<=${format(endOfMonth(month), "yyyy-MM-dd")}`);
    }
    if (selectedTimeRange === "range") {
      if (!dateRange || !dateRange[0] || !dateRange[1]) return;
      const startDate = new Date(dateRange[0]);
      const endDate = new Date(dateRange[1]);
      setTimeRangeQuery(`created_at:>=${format(startDate, "yyyy-MM-dd")} created_at:<=${format(endDate, "yyyy-MM-dd")}`);
    }
  }, [selectedTimeRange, month, dateRange]);

  return (
    <Box>
      <SideBar selectedTab={selectedTab} onTabChange={onTabChange} />
      <Stack direction="row" justifyContent="flex-end">
        <Stack width={{ xs: "100%", md: "calc(100% - 300px)" }} paddingY={3} paddingLeft={2} paddingRight={5}>
          {selectedTab === "artists" && (
            <Artists
              users={searchedUsers || users}
              loading={loading}
              artistSearchValue={artistSearchValue}
              setArtistSearchValue={setArtistSearchValue}
            />
          )}
          {selectedTab === "products" && (
            <Products month={month} setMonth={setMonth} goToNextMonth={goToNextMonth} goToPreviousMonth={goToPreviousMonth} />
          )}
          {selectedTab === "orders" && (
            <Orders
              selectedTimeRange={selectedTimeRange}
              setSelectedTimeRange={setSelectedTimeRange}
              month={month}
              setMonth={setMonth}
              dateRange={dateRange}
              setDateRange={setDateRange}
              timeRangeQuery={timeRangeQuery}
              goToPreviousMonth={goToPreviousMonth}
              goToNextMonth={goToNextMonth}
            />
          )}
          {selectedTab === "payments" && (
            <Payments
              selectedTimeRange={selectedTimeRange}
              setSelectedTimeRange={setSelectedTimeRange}
              month={month}
              setMonth={setMonth}
              dateRange={dateRange}
              setDateRange={setDateRange}
              timeRangeQuery={timeRangeQuery}
              goToPreviousMonth={goToPreviousMonth}
              goToNextMonth={goToNextMonth}
            />
          )}
          {selectedTab === "invoices" && (
            <Invoices month={month} setMonth={setMonth} goToNextMonth={goToNextMonth} goToPreviousMonth={goToPreviousMonth} />
          )}
          {selectedTab === "extras" && <Extras />}
        </Stack>
      </Stack>
    </Box>
  );
};

export default Admin;
