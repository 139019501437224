import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { match } from "ts-pattern";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { formattedPrice } from "../../helpers/money";
import { colors } from "../../theme";
import { Frame } from "../../types/product";
import FrameMockUp from "../FrameMockUp";
import { media } from "../../helpers/layout";
import { useAppState } from "../../state";
import AuthContext from "../../state/auth";
import { AristProductFragment, CountryCode, ProductCardProductFragment } from "../../generated/storefront";
import WishlistBookmark from "../WishlistBookmark";
import AdminSection from "./AdminSection";
import { getFutureDiscountPct } from "../../helpers/basket";
import { FixedWallMockUp } from "../MockUps";

export type ProductCardProduct = (AristProductFragment | Omit<ProductCardProductFragment, "collections">) & {
  collections?: { nodes: { id: string; title: string }[] };
};

export type Props = {
  product: ProductCardProduct;
  index?: number;
  minHeight?: string;
  nextMonths?: boolean;
  indent?: boolean;
  frame?: Frame;
  style?: "artwork" | "interior";
};

export const Card = styled.div`
  position: relative;
  background-color: ${colors.grey02};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  flex-grow: 1;
  min-height: 220px;

  @media ${media.m} {
    padding: 24px;
  }
`;

const ProductCard = ({ product, index, minHeight, indent, frame = Frame.Natural, style = "artwork" }: Props) => {
  const { basket, selectedCountry } = useAppState();
  const { customer, refetchCustomer, isAdmin } = useContext(AuthContext);
  const [imageLoaded, setImageLoaded] = useState(false);
  const image = product.images.nodes[0];
  const secondImage = product.images.nodes[1];
  const { minVariantPrice } = product.priceRange;
  const discountPct = getFutureDiscountPct(basket);
  const showDiscountedPrices = discountPct > 0;
  const showDecimals = selectedCountry === CountryCode.At;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);

  return (
    <Stack gap={1} minHeight={minHeight} height="100%" position="relative">
      <Link
        to={`/products/${product.handle}`}
        style={{ height: "100%", zIndex: 0 }}
        onClick={(e) => popoverOpen && e.preventDefault()}
      >
        <Stack height="100%" width="100%">
          {match(style)
            .with("artwork", () => (
              <Card>
                {!imageLoaded && (
                  <Skeleton
                    width="100%"
                    height="100%"
                    animation="wave"
                    variant="rectangular"
                    sx={{ position: "absolute", bgcolor: colors.grey02 }}
                  />
                )}
                <FrameMockUp frame={frame} image={image} imageLoaded={imageLoaded} setImageLoaded={setImageLoaded} />
              </Card>
            ))
            .with("interior", () => (
              <Box position="relative" height="100%" width="100%" minHeight={220}>
                {!imageLoaded && (
                  <Skeleton
                    width="100%"
                    height="100%"
                    animation="wave"
                    variant="rectangular"
                    sx={{ position: "absolute", bgcolor: colors.grey02 }}
                  />
                )}
                <FixedWallMockUp index={index} image={image} secondImage={secondImage} setImageLoaded={setImageLoaded} />
              </Box>
            ))
            .exhaustive()}
          <Stack gap={{ xs: 0.2, md: 0.25 }} paddingX={indent ? 1.5 : 0} paddingTop={2}>
            <Stack gap={1} direction="row" justifyContent="space-between">
              <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
                {product.title}
              </Typography>
              <WishlistBookmark
                productId={product.id}
                customer={customer}
                refetchCustomer={refetchCustomer}
                popoverOpen={popoverOpen}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
              />
            </Stack>

            <Stack gap={{ xs: 0.3, md: 0.5 }}>
              <Link to={`/artists/${product.vendor}`}>
                <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
                  {product.artistName?.value || ""}
                </Typography>
              </Link>
              <Stack gap={{ xs: 0.75, md: 1 }} direction="row">
                <Stack direction="row" gap={{ xs: 0.5, md: 0.75 }}>
                  <Typography variant="body2">From</Typography>
                  <Typography
                    variant="body2"
                    style={{ textDecoration: showDiscountedPrices ? "line-through" : "none" }}
                    color={showDiscountedPrices ? "text.disabled" : "primary"}
                  >
                    {formattedPrice(minVariantPrice.amount, minVariantPrice.currencyCode, showDecimals)}
                  </Typography>
                </Stack>
                {showDiscountedPrices && (
                  <Typography variant="body2" color={colors.red}>
                    {formattedPrice(
                      Number(minVariantPrice.amount) * (1 - discountPct),
                      minVariantPrice.currencyCode,
                      showDecimals
                    )}{" "}
                    (-{discountPct * 100}%)
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Link>
      {isAdmin && <AdminSection product={product} />}
    </Stack>
  );
};

export default ProductCard;
