import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import { format } from "date-fns";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { formattedPrice } from "../../../../helpers/money";
import TextLink from "../../../../components/TextLink";
import { getIdNumber } from "../../../../helpers/shopify";
import { getOrderItemTotals } from "../../../../helpers/prices";
import { CurrencyCode } from "../../../../generated/storefront";
import { useGetAdminOrdersQuery } from "../../../../generated/graphql";
import Button from "../../../../components/Button";
import { fonts } from "../../../../theme";
import Switch from "../../../../components/Switch";
import MonthPicker from "../../../../components/MonthPicker";
import { dataGridStyles } from "../../styles";

const defaultPageSettings = {
  pageSize: 25,
  page: 0,
};

type Props = {
  selectedTimeRange: "month" | "range";
  setSelectedTimeRange: (value: "month" | "range") => void;
  month: Date;
  setMonth: (value: Date) => void;
  dateRange: DateRange<string>;
  setDateRange: (value: DateRange<string>) => void;
  timeRangeQuery: string;
  goToPreviousMonth: () => void;
  goToNextMonth: () => void;
};

const Orders = ({
  selectedTimeRange,
  setSelectedTimeRange,
  month,
  setMonth,
  dateRange,
  setDateRange,
  timeRangeQuery,
  goToPreviousMonth,
  goToNextMonth,
}: Props) => {
  const [paginationModel, setPaginationModel] = useState(defaultPageSettings);
  const { data, loading, fetchMore } = useGetAdminOrdersQuery({
    variables: {
      query: timeRangeQuery,
    },
  });
  const hasMoreOrders = data?.orders?.pageInfo?.hasNextPage;
  const afterCursor = data?.orders?.pageInfo?.endCursor;
  const loadingOrders = hasMoreOrders || loading;
  const orders = data?.orders?.nodes || [];

  const columns: GridColDef[] = [
    { field: "date", headerName: "Date", flex: 1 },
    { field: "customer", headerName: "Customer", flex: 1 },
    { field: "total", headerName: "Total", flex: 1 },
    { field: "discountedTotal", headerName: "Discounted Total", flex: 1 },
    { field: "discountCode", headerName: "Discount Code", flex: 1 },
    { field: "itemQty", headerName: "Item Qty" },
    {
      field: "orderLink",
      headerName: "Order Link",
      renderCell: (params) => (
        <TextLink openInNewTab href={`https://goodmoodprints.myshopify.com/admin/orders/${getIdNumber(params.row.id, "Order")}`}>
          <Typography fontSize={14} fontFamily={fonts.body}>
            View
          </Typography>
        </TextLink>
      ),
    },
  ];

  const formattedOrders = (orders || []).map(({ id, customer, discountCode, lineItems, createdAt }) => {
    const date = format(new Date(createdAt), "dd MMM yyyy");
    const { orderTotal, discountTotal } = getOrderItemTotals(lineItems.nodes.map((i) => ({ ...i, orderCreatedAt: createdAt })));
    return {
      id,
      date,
      customer: customer.firstName + " " + customer.lastName,
      total: formattedPrice(orderTotal, CurrencyCode.Gbp),
      discountedTotal: formattedPrice(orderTotal - discountTotal, CurrencyCode.Gbp),
      discountCode,
      itemQty: lineItems.nodes.length,
    };
  });

  useEffect(() => {
    if (hasMoreOrders && afterCursor) {
      fetchMore({
        variables: {
          query: timeRangeQuery,
          afterCursor,
        },
      });
    }
  }, [afterCursor, fetchMore, hasMoreOrders, timeRangeQuery]);

  return (
    <Box sx={{ height: "80vh", width: "100%" }}>
      <Stack direction="row" alignItems="center" gap={2} paddingBottom={2}>
        <Switch
          options={[
            { label: "Month", value: "month" },
            { label: "Range", value: "range" },
          ]}
          selected={selectedTimeRange}
          onChange={(value) => setSelectedTimeRange(value as "month" | "range")}
        />
        <Stack direction="row" gap={1} alignItems="center">
          {match(selectedTimeRange)
            .with("month", () => (
              <MonthPicker
                month={month}
                setMonth={setMonth}
                loading={loadingOrders}
                goToNextMonth={goToNextMonth}
                goToPreviousMonth={goToPreviousMonth}
              />
            ))
            .with("range", () => (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  value={dateRange}
                  onChange={setDateRange}
                  localeText={{ start: "Start", end: "End" }}
                  disableFuture
                />
              </LocalizationProvider>
            ))
            .exhaustive()}
        </Stack>
        <Link to="/admin/checkout" style={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
          <Button size="small" secondary>
            Create order
          </Button>
        </Link>
      </Stack>
      <DataGrid
        loading={loadingOrders}
        rows={formattedOrders}
        columns={columns}
        pageSizeOptions={[]}
        pagination
        rowCount={orders?.length}
        rowHeight={50}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        style={{ height: loadingOrders ? "70vh" : "auto", minHeight: "70vh" }}
        sx={dataGridStyles}
      />
    </Box>
  );
};

export default Orders;
