import { useEffect, useMemo, useRef, useState } from "react";
import { useToggle } from "react-use";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { BookmarkBorderOutlined, Search } from "@mui/icons-material";
import SearchBar from "./SearchBar";
import { NavBar, NavItem, NavWrapper } from "./styles";
import { useAppState } from "../../state";
import MobileNav from "./Mobile";
import BasketNavItem from "./BasketNavItem";
import Banner from "../Banner";
import { BasketFragment } from "../../generated/storefront";
import Account from "./Account";
import useBasketActions from "../../hooks/useBasketActions";
import ShopNavItem from "./ShopNavItem";

const Nav = () => {
  const [serachParams] = useSearchParams();
  const basketIdParam = serachParams.get("basket");
  const { navHeight, basket, isMobileScreen, itemAdded, setItemAdded } = useAppState();
  const [searchOpen, setSearchOpen] = useState(false);
  const [showBasket, setShowBasket] = useState(false);
  const [showMobileNav, toggleMobileNav] = useToggle(false);
  const [basketToView, setBasketToView] = useState<BasketFragment | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { getBasket } = useBasketActions();
  const location = useLocation();

  const navItems = [
    { title: "Gallery Walls", link: "/gallery-walls" },
    { title: "Print Sets", link: "/print-sets" },
    { title: "Artists", link: "/artists" },
    { title: "About", link: "/about" },
    { title: "Blog", link: "/goodnews" },
    { title: "Sell", link: "/sell" },
  ];

  const getBasketQuantity = (basket?: BasketFragment) => {
    let qty = 0;
    const items = basket?.lines.nodes.filter((item) => !item?.merchandise.sku?.startsWith("M-")) || [];
    if (items) {
      items.forEach((item: any) => (qty += item.quantity));
    }
    return qty;
  };

  const basketQuantity = useMemo(() => getBasketQuantity(basket), [basket]);

  const closeBasket = () => {
    setShowBasket(false);
    setBasketToView(null);
    if (itemAdded) {
      setItemAdded(false);
    }
  };

  const getSharedBasket = async () => {
    if (basketIdParam) {
      const result = await getBasket({ variables: { basketId: basketIdParam } });
      if (result.data?.cart) {
        setBasketToView(result.data?.cart);
        setShowBasket(true);
      }
    }
  };

  useEffect(() => {
    getSharedBasket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basketIdParam]);

  useEffect(() => {
    if (itemAdded) {
      setShowBasket(true);
    }
  }, [itemAdded, setShowBasket]);

  useEffect(() => {
    setSearchOpen(false);
  }, [location]);

  return (
    <NavWrapper>
      <Banner closeMobileNav={() => toggleMobileNav(false)} />
      <NavBar ref={ref}>
        {isMobileScreen ? (
          <MobileNav
            showNav={showMobileNav}
            toggleNav={toggleMobileNav}
            basketQuantity={basketQuantity}
            closeBasket={closeBasket}
            basket={basketToView || basket}
            showBasket={showBasket}
            setShowBasket={setShowBasket}
            setSearchOpen={setSearchOpen}
            viewOnly={!!basketToView}
          />
        ) : (
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
              <Link to="/" style={{ flex: 1 }}>
                <NavItem variant="h2">GoodMood</NavItem>
              </Link>
              <Stack direction="row" alignItems="center" justifyContent="center" gap={4} style={{ flex: 1 }}>
                <ShopNavItem navHeight={navHeight} />
                {navItems.map((item) => (
                  <Link to={item.link} key={item.title}>
                    <NavItem fontSize={14}>{item.title}</NavItem>
                  </Link>
                ))}
              </Stack>
              <Stack direction="row" alignItems="center" gap={2} style={{ flex: 1 }} justifyContent="end">
                <Account />
                <Stack direction="row" gap={2}>
                  <NavItem fontSize={14} onClick={() => setSearchOpen(true)}>
                    <Search fontSize="small" />
                  </NavItem>
                  <Link to="/wishlist">
                    <NavItem fontSize={14}>
                      <BookmarkBorderOutlined fontSize="small" />
                    </NavItem>
                  </Link>
                  <BasketNavItem
                    basket={basketToView || basket}
                    showBasket={showBasket}
                    setShowBasket={setShowBasket}
                    closeBasket={closeBasket}
                    basketQuantity={basketQuantity}
                    isMobileScreen={false}
                    viewOnly={!!basketToView}
                  />
                </Stack>
              </Stack>
            </Stack>
          </>
        )}
        {searchOpen && <SearchBar setSearchOpen={setSearchOpen} />}
      </NavBar>
    </NavWrapper>
  );
};

export default Nav;
