import { Helmet } from "react-helmet";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import contact from "../../assets/images/contact.png";
import { Container, Flex } from "../../components/Layout";
import { Header, Text } from "../../components/Text";
import TextLink from "../../components/TextLink";
import { useAppState } from "../../state";
import { deliveryCountries } from "../../state/constants";
// import XmasLastDeliveryDates from "../../components/XmasLastDeliveryDates";

const Delivery = () => {
  const { isMobileScreen } = useAppState();

  return (
    <>
      <Helmet>
        <title>Delivery & Returns | GoodMood</title>
        <meta name="description" content="We deliver tracked to most countries in the world" />
      </Helmet>
      <PageHeader header="Delivery & Returns" background={colors.blue} image={contact} />
      <Container padding="40px 0 300px">
        <Flex gap={72} justifyContent="center" alignItems="center" direction="column">
          <Container width={isMobileScreen ? 100 : 50} padding={isMobileScreen ? "0 24px" : ""}>
            <Header type="h2" margin="0 0 16px">
              Delivery:
            </Header>
            {/* <XmasLastDeliveryDates /> */}
            {/* <Margin margin={36} /> */}
            <Flex gap={16} direction="column">
              <Text size={20}>
                We deliver to most countries in the world as we have global fulfilment coverage. We always ship using a tracked
                service. Typically you will receive an email when your order has been dispatched, which will include tracking
                information. If you can’t find tracking information, then please contact us on{" "}
                <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>
              </Text>

              <Text size={20}>
                In the UK, EU, US & Australia we charge £4.95 for an unframed print and £9.95 for a framed print (or equivalent
                local price). We charge more for other destinations, depending where you are purchasing.
              </Text>
              <Text size={20}>
                Typically our orders are dispatched within 2 working days. Therefore for orders in UK, EU, US and Australia you
                should expect to receive orders within 5 working days. For orders to other parts of the world, please allow up to
                10 working days.
              </Text>
              <Text size={20}>We currently offer delivery to the following countries: {deliveryCountries.join(", ")}.</Text>
              <Text size={20} bold>
                Factors which could delay delivery times include:
              </Text>
              <ul style={{ margin: 0 }}>
                <li>
                  <Text size={20}>Mistyped or incorrectly formatted address</Text>
                </li>
                <li>
                  <Text size={20}>Customs and import restrictions</Text>
                </li>
                <li>
                  <Text size={20}>Failed delivery attempts (i.e. no one home to receive the order)</Text>
                </li>
                <li>
                  <Text size={20}>
                    If there is a long delay with your order then please contact us at{" "}
                    <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink> and we will deal with this
                    as best we can
                  </Text>
                </li>
              </ul>
            </Flex>
          </Container>
          <Container width={isMobileScreen ? 100 : 50} padding={isMobileScreen ? "0 24px" : ""}>
            <Header type="h2" margin="0 0 16px">
              Customs & Duties:
            </Header>
            <Flex gap={16} direction="column">
              <Text size={20}>
                Any orders within the UK, EU, US & Australia will not incur custom fees and duties. For all other destinations,
                duty and custom fees are unlikely, however they may be incurred, and it is the responsibility of the end customer
                to pay for this.
              </Text>
              <Text size={20}>
                We have no control over these charges, so GoodMood cannot advise on specific orders and it is best to contact your
                local custom authorities for more information. If your order incurs import duties and taxes in your country, they
                must be paid directly to your tax authority or the courier, and then shipment will be released to you.{" "}
              </Text>
              <Text size={20}>
                {" "}
                If you do not pay and the delivery is returned to us we will need to recharge postage to send it back to you.
              </Text>
            </Flex>
          </Container>
          <Container width={isMobileScreen ? 100 : 50} padding={isMobileScreen ? "0 24px" : ""}>
            <Header type="h2" margin="0 0 16px">
              Packaging:
            </Header>
            <Flex gap={16} direction="column">
              <Text size={20}>All prints and framed prints are very carefully packaged. </Text>
              <Text size={20}>
                {" "}
                Our unframed prints are hand rolled in protective tissue paper and then shipped in extra thick cardboard tubes.{" "}
              </Text>
              <Text size={20}>
                All framed prints are wrapped in a protective sleeve with individual plastic or cardboard guards added to each
                corner. Additional cardboard is secured over the print surface to minimise the risk of breakages or transit
                damage. Each print is then shipped in a heavy duty cardboard box.
              </Text>
              <Text size={20}>
                It is extremely rare for breakages or damages to occur, as our packaging solution has been refined many times. On
                the rare occasion when damage does happen, we ask for photographic evidence and then we will strive to organise
                collection and replacement immediately.
              </Text>
            </Flex>
          </Container>
          <Container width={isMobileScreen ? 100 : 50} padding={isMobileScreen ? "0 24px" : ""}>
            <Header type="h2" margin="0 0 16px">
              Returns Policy:
            </Header>
            <Text size={20}>
              Please contact us by emailing <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>{" "}
              within 30 days of receiving an order and we will be happy to process a return and refund. Items will have to be
              returned to our UK address. You will need to cover the cost of delivery, and we would recommend you send your return
              with a tracked service. We will also not refund original delivery charges. The item must be returned in original
              condition and in its original packaging. Once we receive the item, we will process a refund or exchange.
            </Text>
          </Container>
        </Flex>
      </Container>
    </>
  );
};

export default Delivery;
