import { useEffect, useState, useRef } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { sortBy } from "lodash";
import { Badge, Box, Grid, Stack, Typography } from "@mui/material";
import placeholder from "../../assets/images/placeholder.webp";
import { User } from "../../types/user";
import PageHeader from "../../components/PageHeader";
import { colors } from "../../theme";
import { Header } from "../../components/Text";
import ProductCard from "../../components/ProductCard";
import { isNextMonth } from "../../helpers/time";
import { getUserById } from "../../services/Firebase";
import { Loader } from "../../components/Loader";
import Button from "../../components/Button";
import { useAppState } from "../../state";
import { getUserByPermalink } from "../../services/Firebase/users";
import { ProductSortKeys, useGetArtistProductsQuery } from "../../generated/storefront";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import Switch from "../../components/Switch";
import FiltersDrawer from "../Shop/Filters/Drawer";
import FiltersDesktopDrawer from "../Shop/Filters/DesktopDrawer";
import { useToggle } from "react-use";
import { FiltersButton } from "../Shop/styles";
import { Icon } from "../../components/Icon";
import FrameBadge from "../../components/FrameBadges/FrameBadge";
import FrameBadges from "../../components/FrameBadges";
import { Frame } from "../../types/product";
import BottomSheet from "../../components/BottomSheet";
import { Margin } from "../../components/Layout";

const Artist = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [artist, setArtist] = useState<User>();
  const [loadingArtist, setLoadingArtist] = useState(true);
  const [showFilters, toggleShowFilters] = useToggle(false);
  const [selectedFrame, setSelectedFrame] = useState(Frame.Natural);
  const [openFrameBar, setOpenFrameBar] = useState(false);
  const { selectedCountry, isMobileScreen, navHeight } = useAppState();
  const style = searchParams.get("style");
  const subject = searchParams.get("subject");
  const styles = style?.split(",") || [];
  const subjects = subject?.split(",") || [];
  const filtersQuery = [...styles, ...subjects].map((tag) => `tag:gm.${tag}`).join(" OR ");
  const { data, loading, fetchMore } = useGetArtistProductsQuery({
    variables: {
      limit: 250,
      query: `vendor:${artist?.id} ${filtersQuery}`,
      sortKey: ProductSortKeys.BestSelling,
      country: selectedCountry,
    },
  });
  const products = sortBy(data?.products.nodes, (product) => product.artistPosition?.value);
  const hasMore = data?.products.pageInfo.hasNextPage || false;
  const afterCursor = data?.products.pageInfo.endCursor || "";
  const filters = ["style", "subject"];
  let filterCount = 0;
  searchParams.forEach((value, key) => filters.includes(key) && value && filterCount++);
  const availableTags = (products?.flatMap((product) => product.tags) || []).map((tag) => tag.replace("gm.", ""));

  useLoadMoreOnScroll(
    ref,
    () =>
      fetchMore({
        variables: {
          afterCursor,
        },
      }),
    hasMore,
    loading
  );

  const getArtist = async () => {
    if (!id) return;
    const user = (await getUserById(id)) || (await getUserByPermalink(id));
    setArtist(user);
    setLoadingArtist(false);
  };

  const saveScrollPosition = () => {
    window.sessionStorage.setItem("scrollPosition", window.scrollY.toString());
  };

  const onViewChange = (value: string) => {
    searchParams.set("view", value);
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const clearFilters = () => {
    searchParams.delete("style");
    searchParams.delete("subject");
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setLoadingArtist(true);
    getArtist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <Helmet>
        {artist && <title>{`${artist.firstName} ${artist.lastName}`} | GoodMood</title>}
        {artist?.about && <meta name="description" content={artist.about} />}
        {artist && <link rel="canonical" href={`https://goodmoodprints.io/artists/${artist.permalink}`} />}
      </Helmet>

      <Box minHeight={!artist || !products || loading ? `${window.sessionStorage.getItem("scrollPosition")}px` : "auto"}>
        {loadingArtist ? (
          <Box padding={4}>
            <Loader />
          </Box>
        ) : artist?.firstName ? (
          <>
            <PageHeader
              header={`${artist.firstName} ${artist.lastName}`}
              background={artist.backgroundColour || colors.mint}
              image={artist.image || placeholder}
            >
              {artist.about}
            </PageHeader>
            <Margin margin={8} />
            <Stack
              position="sticky"
              top={navHeight}
              zIndex={1}
              gap={1}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              paddingX={{ xs: 2, md: 5 }}
              paddingY={1}
              bgcolor={colors.white}
            >
              {isMobileScreen ? (
                <Badge badgeContent={filterCount} color="secondary">
                  <FiltersButton onClick={toggleShowFilters}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Icon icon="filters" fill={colors.black} size={20} />
                    </Stack>
                  </FiltersButton>
                </Badge>
              ) : (
                <Stack minWidth={200} alignItems="flex-start" justifyContent="center">
                  <FiltersButton onClick={toggleShowFilters}>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography fontSize={12}>Filters{filterCount > 0 ? ` (${filterCount})` : ""}</Typography>
                      <Icon icon="filters" fill={colors.black} size={20} />
                    </Stack>
                  </FiltersButton>
                </Stack>
              )}

              <Switch
                options={[
                  {
                    label: "Artwork",
                    value: "artwork",
                  },
                  {
                    label: "Interior",
                    value: "interior",
                  },
                ]}
                fullWidth={isMobileScreen}
                selected={searchParams.get("view") || "artwork"}
                onChange={onViewChange}
              />

              {isMobileScreen ? (
                <Stack
                  onClick={() => setOpenFrameBar(true)}
                  component="button"
                  position="relative"
                  bgcolor={colors.white}
                  border={`2px solid ${colors.cardGrey}`}
                  borderRadius={100}
                  padding={0.25}
                >
                  <FrameBadge frame={selectedFrame} size={30} />
                </Stack>
              ) : (
                <Stack direction="row" alignItems="center" justifyContent="flex-end" minWidth={200}>
                  <FrameBadges selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} size="medium" />
                </Stack>
              )}
            </Stack>

            {isMobileScreen ? (
              <FiltersDrawer
                isOpen={showFilters}
                onClose={toggleShowFilters}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                hideSortBy
                filterGroupsToHide={["orientation", "month", "size", "medium"]}
                availableTags={availableTags}
              />
            ) : (
              <FiltersDesktopDrawer
                isOpen={showFilters}
                onClose={toggleShowFilters}
                filterCount={filterCount}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                filterGroupsToHide={["orientation", "month", "size", "medium"]}
                availableTags={availableTags}
              />
            )}

            <Box ref={ref} padding={isMobileScreen ? "8px 0 24px" : "8px 40px 40px"}>
              {products && !loading ? (
                products.length === 0 ? (
                  filterCount > 0 ? (
                    <Box padding="32px 24px 400px">
                      <Stack alignItems="center" gap={2}>
                        <Typography>No pieces with selected filters</Typography>
                        <Button size="medium" onClick={clearFilters}>
                          Clear filters
                        </Button>
                      </Stack>
                    </Box>
                  ) : (
                    <Box padding="32px 24px 400px">
                      <Stack alignItems="center">
                        <Header type="h2" margin="0 0 16px 0" align="center">
                          Nothing uploaded yet
                        </Header>
                        <Link
                          to={{
                            pathname: "/shop",
                            search: "?collection=true",
                          }}
                        >
                          <Button secondary>Explore the collection</Button>
                        </Link>
                      </Stack>
                    </Box>
                  )
                ) : (
                  <>
                    <Grid container spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }}>
                      {products.map((product, index) => (
                        <Grid item xs={6} md={3} key={product.id} onClick={saveScrollPosition}>
                          <ProductCard
                            product={product}
                            index={index}
                            frame={selectedFrame}
                            nextMonths={isNextMonth(product.productType)}
                            indent={isMobileScreen}
                            style={(searchParams.get("view") as "artwork" | "interior") || "artwork"}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    {loading ? (
                      <Loader />
                    ) : hasMore ? (
                      <Stack width="100%" alignItems="center" padding={5}>
                        <Button onClick={() => fetchMore({ variables: { afterCursor } })}>Load more</Button>
                      </Stack>
                    ) : null}
                  </>
                )
              ) : (
                <Loader />
              )}
            </Box>
          </>
        ) : (
          <Box padding="40px">
            <Stack gap={2} justifyContent="center" alignItems="center">
              <Typography variant="h2" component="h1">
                Artist not found
              </Typography>
              <Link to="/artists">
                <Button secondary>View all artists</Button>
              </Link>
            </Stack>
          </Box>
        )}
      </Box>

      {isMobileScreen && (
        <BottomSheet open={openFrameBar} onClose={() => setOpenFrameBar(false)}>
          <Typography>Frame colour</Typography>
          <FrameBadges
            selectedFrame={selectedFrame}
            setSelectedFrame={(frame: Frame) => {
              setSelectedFrame(frame);
              setOpenFrameBar(false);
            }}
            size="large"
            showLabels
          />
        </BottomSheet>
      )}
    </>
  );
};

export default Artist;
