import { useEffect, useState } from "react";
import { ArrowDropDownRounded, Delete } from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { getInvoiceById, updateInvoice } from "../../../../../services/Firebase/invoices";
import { InvoiceItem, InvoiceStatus, Invoice as TInvoice } from "../../../../../types/invoice";
import { Container, Form, Grid, GridItem, Margin } from "../../../../../components/Layout";
import { IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import { Header, Text } from "../../../../../components/Text";
import { format } from "date-fns";
import { colors } from "../../../../../theme";
import { formattedPrice } from "../../../../../helpers/money";
import Button from "../../../../../components/Button";
import { useToggle } from "react-use";
import { Loader } from "../../../../../components/Loader";
import Modal from "../../../../../components/Modal";
import Input from "../../../../../components/Form/Input";
import { CurrencyCode } from "../../../../../generated/storefront";

const Invoice = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState<TInvoice>();
  const [error, setError] = useState("");
  const [showModal, toggleModal] = useToggle(false);
  const [itemData, setItemData] = useState<Partial<InvoiceItem> & { customDescription: string }>({
    description: "Refunds",
    customDescription: "",
  });

  const getInvoice = async () => {
    if (!id) return;
    const inv = await getInvoiceById(id);
    setInvoice(inv);
  };

  const onInputChange = ({ target }: any) => {
    setItemData({ ...itemData, [target.name]: target.value });
  };

  const addItem = async (event: any) => {
    event.preventDefault();
    if (!id) return;
    const { description, amount } = itemData;
    if (!description || !amount) return setError("Please fill in all fields");
    const descriptionValue = description === "Other" ? itemData.customDescription : description;
    const updatedInvoice = await updateInvoice(id, {
      items: [...(invoice?.items || []), { id: uuid(), description: descriptionValue, amount, custom: true }],
    });
    setInvoice(updatedInvoice);
    toggleModal();
  };

  const removeItem = async (itemId: string) => {
    if (!id) return;
    const updatedItems = (invoice?.items || []).filter((i) => i.id !== itemId);
    const updatedInvoice = await updateInvoice(id, {
      items: updatedItems,
    });
    setInvoice(updatedInvoice);
  };

  useEffect(() => {
    getInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  if (invoice) {
    const { artist, vatNumber, invoiceNumber, issueDate, amount } = invoice;
    const editable = invoice.status === InvoiceStatus.DRAFT;
    const customItems = (invoice.items || []).filter((i) => i.custom);
    const customItemTotal = customItems.reduce((total, i) => total + Number(i.amount), 0);
    return (
      <Container padding="45px" style={{ height: 1080, width: 840 }} margin="auto">
        <Stack spacing={3} direction="column" flexGrow={1} width="100%">
          <div>
            <Stack>
              <Text size={20} align="right" font="Arial">
                {artist}
              </Text>
              {vatNumber && <Text font="Arial">VAT/GST number: {vatNumber}</Text>}
            </Stack>
            <Stack spacing={2} direction="row" justifyContent="end" alignItems="baseline">
              <Stack justifyContent="end">
                <Text align="right" font="Arial">
                  Invoice Reference: GM{invoiceNumber}
                </Text>
                <Text font="Arial" align="right">
                  Date of issue: {format(issueDate.toDate(), "dd/MM/yyyy")}
                </Text>
              </Stack>
            </Stack>
          </div>
          <Margin margin={60} />
          {editable && (
            <Stack direction="row" justifyContent="end">
              <Button size="medium" onClick={toggleModal} style={{ width: "fit-content" }}>
                Add invoice item
              </Button>
            </Stack>
          )}
          <Grid columns={2}>
            <GridItem backgroundColor={colors.purple} outline={false}>
              <Text font="Arial">ITEM</Text>
            </GridItem>
            <GridItem backgroundColor={colors.purple} align="right" outline={false}>
              <Text font="Arial">SUBTOTAL</Text>
            </GridItem>
            <GridItem backgroundColor={colors.purple10} outline={false}>
              <Text font="Arial">GoodMood Commission {format(issueDate.toDate(), "MMMM yy")}</Text>
            </GridItem>
            <GridItem backgroundColor={colors.purple10} align="right" outline={false}>
              <Text font="Arial">{formattedPrice(amount, CurrencyCode.Gbp)}</Text>
            </GridItem>

            {customItems.map((item) => (
              <>
                <GridItem backgroundColor={colors.purple10} outline={false}>
                  <Text font="Arial">{item.description}</Text>
                  {editable && (
                    <IconButton onClick={() => removeItem(item.id)}>
                      <Delete sx={{ color: colors.error, fontSize: "18px" }} />
                    </IconButton>
                  )}
                </GridItem>
                <GridItem backgroundColor={colors.purple10} align="right" outline={false}>
                  <Text font="Arial">{formattedPrice(item.amount, CurrencyCode.Gbp)}</Text>
                </GridItem>
              </>
            ))}

            <Margin margin={60} />
            <Margin margin={60} />
            <Margin margin={47} />
            <GridItem backgroundColor={colors.purple} style={{ justifyContent: "space-between" }} outline={false}>
              <Text font="Arial">TOTAL DUE:</Text>
              <Text font="Arial">{formattedPrice(amount + customItemTotal, CurrencyCode.Gbp)}</Text>
            </GridItem>
          </Grid>
        </Stack>
        {showModal && (
          <Modal onClose={toggleModal} size="small">
            <Stack direction="column" gap={3}>
              <Header>Add invoice item</Header>
              <Form>
                <Stack direction="column" gap={1}>
                  <Typography>Description</Typography>
                  <Select
                    placeholder="Description"
                    name="description"
                    variant="outlined"
                    label="Description"
                    value={itemData.description}
                    onChange={onInputChange}
                    IconComponent={ArrowDropDownRounded}
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="Refunds">
                      <Typography fontSize={14}>Refunds</Typography>
                    </MenuItem>
                    <MenuItem value="Trade order commission">
                      <Typography fontSize={14}>Trade order commission</Typography>
                    </MenuItem>
                    <MenuItem value="Other">
                      <Typography fontSize={14}>Other</Typography>
                    </MenuItem>
                  </Select>
                  {itemData.description === "Other" && (
                    <Input
                      name="customDescription"
                      id="customDescription"
                      placeholder="Custom description"
                      onChange={onInputChange}
                    />
                  )}
                  <Input label="Amount" name="amount" id="amount" onChange={onInputChange} type="number" />
                </Stack>
                {error && <Text color={colors.error}>{error}</Text>}
                <Button onClick={addItem}>Add</Button>
              </Form>
            </Stack>
          </Modal>
        )}
      </Container>
    );
  }
  return <Loader />;
};

export default Invoice;
