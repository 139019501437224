import {
  ArtistProductVariantFragment,
  AdminOrderFragment,
  AdminOrderLineItemFragment,
  ArtistOrderFragment,
} from "../generated/graphql";
import { CurrencyCode, ProductPageVariantFragment } from "../generated/storefront";
import { Invoice } from "../types/invoice";
import { RectangleSize, SquareSize, Size } from "../types/product";
import { roundPrice } from "./money";
import { getSizeAndFrameFromVariantTitle } from "./product";

const oldFramePrices = {
  [RectangleSize.A3]: 50,
  [RectangleSize.A2]: 70,
  [RectangleSize["20X28"]]: 80,
  [RectangleSize.A1]: 100,
  [RectangleSize["28X40"]]: 110,
  [SquareSize["12X12"]]: 50,
  [SquareSize["20X20"]]: 70,
  [SquareSize["28X28"]]: 100,
};

const juneFramePrices = {
  [RectangleSize.A3]: 30,
  [RectangleSize.A2]: 50,
  [RectangleSize["20X28"]]: 60,
  [RectangleSize.A1]: 80,
  [RectangleSize["28X40"]]: 90,
  [SquareSize["12X12"]]: 30,
  [SquareSize["20X20"]]: 50,
  [SquareSize["28X28"]]: 80,
};

const framePrices = {
  [RectangleSize.A3]: 40,
  [RectangleSize.A2]: 60,
  [RectangleSize["20X28"]]: 70,
  [RectangleSize.A1]: 90,
  [RectangleSize["28X40"]]: 100,
  [SquareSize["12X12"]]: 40,
  [SquareSize["20X20"]]: 60,
  [SquareSize["28X28"]]: 90,
};

export const getPrices = (basePrice: number) => {
  let increment = 20;

  if (basePrice >= 60) {
    increment += 10;
  }

  const a3 = basePrice;
  const a2 = basePrice + increment;
  const fiftyBy70 = basePrice + increment * 1.5;
  const a1 = basePrice + increment * 2;
  const seventyBy100 = basePrice + increment * 3.5;
  // const a0 = basePrice + increment * 4;

  return {
    [RectangleSize.A3]: a3,
    [RectangleSize.A2]: a2,
    [RectangleSize["20X28"]]: fiftyBy70,
    [RectangleSize.A1]: a1,
    [RectangleSize["28X40"]]: seventyBy100,
    [SquareSize["12X12"]]: a3,
    [SquareSize["20X20"]]: a2,
    [SquareSize["28X28"]]: a1,
    [`${RectangleSize.A3}Framed`]: a3 + framePrices.A3,
    [`${RectangleSize.A2}Framed`]: a2 + framePrices.A2,
    [`${RectangleSize["20X28"]}Framed`]: fiftyBy70 + framePrices["20X28"],
    [`${RectangleSize.A1}Framed`]: a1 + framePrices.A1,
    [`${RectangleSize["28X40"]}Framed`]: seventyBy100 + framePrices["28X40"],
    // [`${RectangleSize.A0}Framed`]: a0 + 140,
    [`${SquareSize["12X12"]}Framed`]: a3 + framePrices["12X12"],
    [`${SquareSize["20X20"]}Framed`]: a2 + framePrices["20X20"],
    [`${SquareSize["28X28"]}Framed`]: a1 + framePrices["28X28"],
  };
};

export const getLowestPrice = (variants: ProductPageVariantFragment[]) => {
  const prices = variants.map((variant) => Number(variant.price.amount));
  return Math.min(...prices);
};

export const getLowestPriceAdmin = (variants: ArtistProductVariantFragment[]) => {
  const prices = variants.map((variant) => Number(variant.price));
  return Math.min(...prices);
};

export const getHighestPrice = (variants: ProductPageVariantFragment[]) => {
  const prices = variants.map((variant) => Number(variant.price.amount));
  return Math.max(...prices);
};

export const getMarketPctIncrease = (currencyCode: CurrencyCode) => {
  if (currencyCode === CurrencyCode.Usd) return 0.1;
  if (currencyCode === CurrencyCode.Aud) return 0.17;
  if (currencyCode === CurrencyCode.Eur) return 0.04;
  return 0;
};

export const getCommissionForOrderItem = (item: AdminOrderLineItemFragment & { orderCreatedAt: string }, commissionPct = 0.3) => {
  if (!item.variantTitle) return 0;
  const { size, frame } = getSizeAndFrameFromVariantTitle(item.variantTitle);
  if (!size) return 0;
  if (!frame) return 0;
  const itemTotal = Number(item.originalTotalSet.shopMoney.amount);
  const presentmentCurrency = item.originalTotalSet.presentmentMoney.currencyCode;
  const discountAmount = item.discountAllocations.length
    ? Number(item.discountAllocations[0].allocatedAmountSet.shopMoney.amount)
    : 0;

  if (item.orderCreatedAt < "2024-01-01T00:00:00+00:00") {
    const discountedTotal = itemTotal - discountAmount;
    return roundPrice(discountedTotal * commissionPct);
  } else {
    const framePricing =
      item.orderCreatedAt < "2024-05-02T10:00:00+00:00"
        ? oldFramePrices
        : item.orderCreatedAt < "2024-06-25T09:00:00+00:00" || item.orderCreatedAt > "2024-08-06T15:00:00+00:00"
        ? framePrices
        : juneFramePrices;
    const framed = frame !== "unframed";
    const discountPct = roundPrice(discountAmount / Number(itemTotal));
    const baseFramePrice = framed ? framePricing[size as Size] : 0;
    const marketFramePrice = roundPrice(baseFramePrice * (1 + getMarketPctIncrease(presentmentCurrency)));
    const printPrice = roundPrice(itemTotal - marketFramePrice * item.quantity);
    const discountedPrintPrice = printPrice - roundPrice(printPrice * discountPct);
    const roundingCompensation = getMarketPctIncrease(presentmentCurrency) > 0 && framed ? 0.1 : 0; // compensate for market price rounding
    return roundPrice(discountedPrintPrice * commissionPct - roundingCompensation);
  }
};

export const getOrderItemTotals = (orderItems: (AdminOrderLineItemFragment & { orderCreatedAt: string })[]) => {
  const orderTotal = orderItems.reduce((total, i) => total + Number(i.originalTotalSet.shopMoney.amount), 0);
  const discountTotal = orderItems.reduce(
    (total, i) =>
      total + (i.discountAllocations.length ? Number(i.discountAllocations[0].allocatedAmountSet.shopMoney.amount) : 0),
    0
  );

  const commissionTotal = orderItems.reduce((total, i) => total + getCommissionForOrderItem(i), 0);

  return {
    orderTotal: roundPrice(orderTotal),
    discountTotal: roundPrice(discountTotal),
    commissionTotal: roundPrice(commissionTotal),
  };
};

export const getBlackFridayCommissionProtection = (orderItems: (AdminOrderLineItemFragment & { orderCreatedAt: string })[]) => {
  const blackFridayOrderItems = orderItems.filter(
    (i) => i.orderCreatedAt > "2024-11-20T01:16:00+00:00" && i.orderCreatedAt < "2024-12-03T07:00:00+00:00"
  );
  return blackFridayOrderItems.reduce((total, i) => total + getCommissionForOrderItem(i, 0.04), 0);
};

export const getOrdersTotals = (orders: AdminOrderFragment[] | ArtistOrderFragment[]) => {
  if (!orders.length) return { total: 0, discountTotal: 0, commissionTotal: 0 };
  const orderItems = orders.flatMap((o) => o.lineItems.nodes.map((item) => ({ ...item, orderCreatedAt: o.createdAt })));
  const { orderTotal, discountTotal, commissionTotal } = getOrderItemTotals(orderItems);

  return { total: orderTotal, discountTotal, commissionTotal };
};

export const getInvoiceSubtotal = (invoice: Invoice) => {
  const customItems = (invoice.items || []).filter((i) => i.custom);
  const customItemTotal = customItems.reduce((total, i) => total + Number(i.amount), 0);
  return invoice.amount + customItemTotal;
};
