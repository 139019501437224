import { Grid, Stack, Typography } from "@mui/material";
import PrintSetCard from "./PrintSetCard";
import { Loader } from "../../components/Loader";
import { PrintSet } from "./usePrintSets";
import { Frame } from "../../types/product";
import { useRef } from "react";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";

const PrintSetGrid = ({
  printSets,
  selectedFrame,
  loading,
  fetchMore,
  hasMore,
}: {
  printSets: PrintSet[];
  selectedFrame: Frame;
  fetchMore: () => void;
  hasMore: boolean;
  loading: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useLoadMoreOnScroll(ref, fetchMore, hasMore, loading);

  return (
    <Grid container spacing={{ xs: 2, md: 4 }} paddingX={{ xs: 0, md: 5 }} ref={ref}>
      {printSets.length === 0 && (
        <Stack padding={{ xs: 2, md: 5 }} width="100%" alignItems="center">
          <Typography align="center" width="100%">
            No print sets found, try changing your preferences
          </Typography>
        </Stack>
      )}
      {printSets.map((printSet) => (
        <Grid item xs={6} md={4} xl={4} xxl={2} xxxl={1.5} key={printSet.product.id} position="relative">
          <PrintSetCard printSet={printSet} selectedFrame={selectedFrame} indent openInNewWindow />
        </Grid>
      ))}
      {loading && <Loader />}
    </Grid>
  );
};
export default PrintSetGrid;
