import { Stack, Typography } from "@mui/material";
import { BadgeButton } from "../../pages/Shop/styles";
import { Frame, frames } from "../../types/product";
import FrameBadge from "./FrameBadge";
import { capitalize } from "lodash";
import { match } from "ts-pattern";

type Props = {
  selectedFrame: Frame;
  setSelectedFrame: (frame: Frame) => void;
  size?: "small" | "medium" | "large";
  showLabels?: boolean;
};

const FrameBadges = ({ selectedFrame, setSelectedFrame, size = "small", showLabels = false }: Props) => {
  const orderedFrames = [...frames].reverse();
  const diameter = match(size)
    .with("small", () => 22)
    .with("medium", () => 32)
    .with("large", () => 42)
    .exhaustive();
  const gap = match(size)
    .with("small", () => 1)
    .with("medium", () => 1)
    .with("large", () => 2)
    .exhaustive();
  return (
    <Stack direction="row" gap={gap} width="min-content">
      {orderedFrames.map((frame) => (
        <Stack alignItems="center" gap={1}>
          <BadgeButton key={frame} selected={selectedFrame === frame} onClick={() => setSelectedFrame(frame)} size={size}>
            <FrameBadge frame={frame} size={diameter} />
          </BadgeButton>
          {showLabels && <Typography fontSize={10}>{capitalize(frame)}</Typography>}
        </Stack>
      ))}
    </Stack>
  );
};

export default FrameBadges;
