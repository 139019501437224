import { useState } from "react";
import { format, subMonths } from "date-fns";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { Stack, Typography } from "@mui/material";
import Button from "../../../components/Button";
import {
  CurrencyCode,
  ProductSortKeys,
  useGetAdminProductsLazyQuery,
  useGetArtistProductsLazyQuery,
} from "../../../generated/graphql";
import { getLowestPriceAdmin } from "../../../helpers/prices";
import { updateProductFramePrices } from "../../../helpers/scripts";
import { getIdNumber } from "../../../helpers/shopify";
import { getUserById, getUsers } from "../../../services/Firebase";
import { updateProduct } from "../../../services/API";

const Extras = () => {
  const [getProducts] = useGetAdminProductsLazyQuery();
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [afterCursor, setAfterCursor] = useState<string | undefined>();
  const [getArtistProducts] = useGetArtistProductsLazyQuery();
  const [lastUser, setLastUser] = useState<QueryDocumentSnapshot<DocumentData>>();

  const generateCsvOfProducts = async () => {
    const lastMonth = format(subMonths(new Date(), 1), "yyyy-MM");
    const { data } = await getProducts({
      variables: {
        limit: 250,
        query: `tag:favourite status:ACTIVE product_type:${lastMonth}`,
        sortKey: ProductSortKeys.CreatedAt,
        reverse: true,
      },
      fetchPolicy: "network-only",
    });
    const products = data?.products?.nodes || [];
    const csvPromises = products.map(async (product) => {
      const { id, title, descriptionHtml, vendor, images, variants } = product;
      const idNumber = getIdNumber(id);
      const artist = await getUserById(vendor);
      if (!artist) return "";
      const lowestPrice = getLowestPriceAdmin(variants.nodes);
      const productImages = images.nodes.map((image) => image.src);
      productImages.shift();
      const additionalImages = productImages.join(",");
      const description = descriptionHtml
        ?.replace(/<[^>]*>?/gm, "")
        .toString()
        .replace(/"/g, '""');
      return `${idNumber},"${title}","${description}",in_stock,"https://www.goodmoodprints.io/products/${idNumber}","${images.nodes[0].src}","${additionalImages}",${lowestPrice} ${CurrencyCode.Gbp},no,${artist.firstName} ${artist?.lastName},new,0.01 kg\n`;
    });
    const csv = (await Promise.all(csvPromises)).join("");
    const csvString = `id,title,description,availability,link,image link,additional images,price,identifier exists,brand,condition,shipping weight\n${csv}`;
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "products.csv";
    a.click();
  };

  // const updateProducts = async () => {
  //   const { data } = await getProducts({
  //     fetchPolicy: "network-only",
  //     variables: {
  //       limit: 50,
  //       query: "status:ACTIVE tag:favourite",
  //       afterCursor: "eyJsYXN0X2lkIjo4MzI3NjY4OTkwMTY2LCJsYXN0X3ZhbHVlIjo4MzI3NjY4OTkwMTY2fQ==",
  //     },
  //   });
  //   const products = data?.products?.nodes || [];
  //   console.log("products", products);
  //   console.log("updateProducts", data?.products?.pageInfo);
  //   updateProductsVariants(products);
  // };

  const updateProductsArtistName = async (artistId: string, artistName: string) => {
    try {
      const { data } = await getArtistProducts({
        variables: {
          limit: 250,
          query: `vendor:${artistId}`,
        },
        fetchPolicy: "no-cache",
      });
      if (!data) return;
      const nameUpdated = data.products.nodes.some((product) => product.artistName?.value !== artistName);
      console.log("nameUpdated", nameUpdated);
      if (!nameUpdated) return;
      const promises = data.products.nodes.map(async (product) => {
        if (product.artistName?.value === artistName) return;
        await updateProduct(product.id, { metafields: [{ namespace: "custom", key: "artist_name", value: artistName }] });
      });
      await Promise.all(promises);
    } catch (error) {
      console.error(error);
      console.log("artistId", artistId);
    }
  };

  const getAllArtistsAndUpdateProducts = async () => {
    const { users, lastVisible } = await getUsers({ limit: 200, filters: { hasProducts: true }, lastUser });
    console.log("artists", users, lastVisible);
    users.forEach(async (artist) => {
      const { firstName, lastName } = artist;
      const artistName = `${firstName} ${lastName}`;
      await updateProductsArtistName(artist.id, artistName);
    });
    setLastUser(lastVisible);
  };

  const updateProducts = async () => {
    setLoading(true);
    const { data } = await getProducts({
      fetchPolicy: "no-cache",
      variables: {
        limit: 200,
        afterCursor,
        // query: "product:size:12X12",
      },
    });
    if (data?.products) {
      const products = data.products.nodes;
      console.log("products", products, data.products.pageInfo);
      setAfterCursor(data.products.pageInfo.endCursor || undefined);
      await updateProductFramePrices(products);
      setCounter(counter + 1);
    }
    setLoading(false);
  };

  return (
    <Stack gap={2}>
      <h1>Extras</h1>
      <Stack gap={2} direction="row">
        <Button secondary size="medium" onClick={generateCsvOfProducts}>
          Download CSV of recent favourite products
        </Button>
        <Button secondary size="medium" onClick={updateProducts} loading={loading}>
          Update products
        </Button>
        <Button secondary size="medium" onClick={getAllArtistsAndUpdateProducts}>
          Update products with artist name
        </Button>
        <Typography variant="h1">{counter}</Typography>
      </Stack>
    </Stack>
  );
};

export default Extras;
