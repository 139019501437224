import { ReactElement, useEffect, MouseEvent } from "react";
import styled from "styled-components";
import { match } from "ts-pattern";
import { CloseRounded } from "@mui/icons-material";
import { media } from "../../helpers/layout";
import { useAppState } from "../../state";
import { colors } from "../../theme";

type Size = "small" | "medium" | "large";

type Props = {
  children: ReactElement | ReactElement[] | String;
  image?: string;
  imageOnClick?: () => void;
  showImageOnMobile?: boolean;
  onClose: () => void;
  size?: Size;
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(7, 7, 7, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalBody = styled.div<{ size: Size }>`
  ${(p) =>
    match(p.size)
      .with("small", () => `width: 500px; height: auto;`)
      .with("medium", () => `width: 50vw; height: 50vh;`)
      .with("large", () => `width: 80vw; height: 60vh;`)
      .exhaustive()}
  display: flex;
  background: ${colors.white};
  position: relative;
  border-radius: 24px;
  max-width: 1200px;

  @media ${media.m} {
    height: auto;
    width: calc(100vw - 32px);
    flex-direction: column;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 5%;
  overflow: auto;

  @media ${media.m} {
    padding: 24px;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Image = styled.img`
  width: 50%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px 0 0 24px;

  @media ${media.m} {
    width: 100%;
    height: 50%;
    border-radius: 24px 24px 0 0;
    max-height: 40vh;
  }
`;

export const CloseButton = styled.button<{ size: Size }>`
  background: none;
  border: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 16px;
  right: 16px;

  @media ${media.m} {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 12px;
    right: 12px;
    background: ${colors.white};
    border-radius: 100%;
    width: 32px;
    height: 32px;
  }
`;

const Modal = ({ image, imageOnClick, showImageOnMobile = false, children, onClose, size = "large" }: Props) => {
  const { isMobileScreen } = useAppState();

  const stopClose = (event: MouseEvent<HTMLDivElement>) => event.stopPropagation();

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "unset";
    };
  });

  const showImage = Boolean(image) && (isMobileScreen ? showImageOnMobile : true);

  return (
    <Wrapper onClick={onClose}>
      <ModalBody onClick={stopClose} size={size}>
        {showImage && <Image src={image} onClick={imageOnClick} />}
        <ContentWrapper>
          <Content>{children}</Content>
        </ContentWrapper>
        <CloseButton onClick={onClose} size={size}>
          <CloseRounded fontSize="small" color="primary" />
        </CloseButton>
      </ModalBody>
    </Wrapper>
  );
};

export default Modal;
