import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import WebpImage from "react-image-webp";
import { format, subMonths } from "date-fns";
import posthog from "posthog-js";
import { Box, Stack, Typography } from "@mui/material";
import Button from "../../components/Button";
import { colors } from "../../theme";
import How from "../../assets/images/How.png";
import HowWebp from "../../assets/images/How.webp";
import How2 from "../../assets/images/How2.png";
import How2Webp from "../../assets/images/How2.webp";
import photography from "../../assets/images/photography.webp";
import graphic from "../../assets/images/graphic-design.webp";
import mixed from "../../assets/images/mixed-media.webp";
import painting from "../../assets/images/painting.webp";
import placeholder from "../../assets/images/placeholder.webp";
import backgroundImage from "../../assets/images/backgroundImage.png";
import wallBuilderBanner from "../../assets/images/wallBuilderBanner.png";
import wallBuilderBannerWebp from "../../assets/images/wallBuilderBanner.webp";
import MobileWebp from "../../assets/images/mobile.webp";
import MobileBWebp from "../../assets/images/mobile-b.webp";
import DesktopWebp from "../../assets/images/desktop.webp";
import DesktopBWebp from "../../assets/images/desktop-b.webp";
import { Text, Header } from "../../components/Text";
import { Image } from "../../components";
import ProductCard from "../../components/ProductCard";
import ScrollCarousel from "../../components/ScrollCarousel";
import { useAppState } from "../../state";
import { ArtistButton, ImageAndButtonWrapper } from "./styles";
import { getUsers } from "../../services/Firebase";
import { User } from "../../types/user";
import RollingTextBanner from "../../components/RollingTextBanner";
import { Icon } from "../../components/Icon";
import {
  CollectionSortKeys,
  ProductSortKeys,
  useGetGalleryWallCollectionsQuery,
  useGetPrintSetCollectionsQuery,
  useGetProductsForProductCardQuery,
} from "../../generated/storefront";
import { getMonthsDate, getThisMonthsDate } from "../../helpers/time";
import CollectionCard from "../../components/Card/Collection";
import LazyLoadImage from "../../components/Image/LazyLoadImage";
import PrintSetCard from "../PrintSets/PrintSetCard";
import { Frame } from "../../types/product";
import { getIdNumber } from "../../helpers/shopify";

const Home = () => {
  const { fixedNavHeight, isMobileScreen, selectedCountry, showCookieBanner, toggleGalleryWallBuilderIsOpen } = useAppState();
  const [artists, setArtists] = useState<User[]>();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showTextPadding, setShowTextPadding] = useState(false);
  const [initialWindowHeight] = useState(window.innerHeight);
  const windowHeight = isMobileScreen ? initialWindowHeight : window.innerHeight;
  const { data: collectionsData } = useGetGalleryWallCollectionsQuery({ variables: { limit: 20 } });
  const galleryWalls = collectionsData?.collections?.nodes || [];
  const newHomepageFlag = posthog.getFeatureFlag("new-homepage") === "test";

  const { data: mostPopularData, refetch: refetchMostPopular } = useGetProductsForProductCardQuery({
    variables: {
      query: "tag:favourite",
      sortKey: ProductSortKeys.BestSelling,
      country: selectedCountry,
      limit: 20,
    },
  });
  const { data: trendingData, refetch: refetchTrending } = useGetProductsForProductCardQuery({
    variables: {
      query: `product_type:${getThisMonthsDate()} OR product_type:${getMonthsDate(
        subMonths(new Date(), 1)
      )} OR product_type:${getMonthsDate(subMonths(new Date(), 2))} OR product_type:${getMonthsDate(
        subMonths(new Date(), 3)
      )} OR product_type:${getMonthsDate(subMonths(new Date(), 4))}`,
      sortKey: ProductSortKeys.BestSelling,
      country: selectedCountry,
      limit: 20,
    },
  });
  const { data: ourPicksData, refetch: refetchOurPicks } = useGetProductsForProductCardQuery({
    variables: {
      sortKey: ProductSortKeys.BestSelling,
      query: `tag:pick product_type:${getThisMonthsDate()}`,
      country: selectedCountry,
      limit: 20,
    },
  });
  const { data: communityPicksData, refetch: refetchCommunityPicks } = useGetProductsForProductCardQuery({
    variables: {
      sortKey: ProductSortKeys.BestSelling,
      query: "tag:community_favourites",
      country: selectedCountry,
      limit: 20,
    },
  });
  const { data: printSetsData } = useGetPrintSetCollectionsQuery({
    variables: {
      sortKey: CollectionSortKeys.Title,
      limit: 12,
    },
  });

  const mostPopular = mostPopularData?.products?.nodes || [];
  const trending = trendingData?.products?.nodes || [];
  const ourPicks = ourPicksData?.products?.nodes || [];
  const communityPicks = communityPicksData?.products?.nodes || [];
  const printSets = printSetsData?.collections?.nodes || [];

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    const threshold = 100;
    setShowTextPadding(scrollPosition < threshold);
  }, [scrollPosition]);

  const getArtists = async () => {
    const { users } = await getUsers({
      filters: { hasProducts: true },
      orderBy: { field: "salesCount", direction: "desc" },
      limit: 10,
    });
    setArtists(users);
  };

  useEffect(() => {
    if (!artists) {
      getArtists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artists]);

  useEffect(() => {
    refetchMostPopular({
      country: selectedCountry,
    });
    refetchTrending({
      country: selectedCountry,
    });
    refetchOurPicks({
      country: selectedCountry,
    });
    refetchCommunityPicks({
      country: selectedCountry,
    });
  }, [selectedCountry, refetchMostPopular, refetchTrending, refetchOurPicks, refetchCommunityPicks]);

  const imageHeight = windowHeight - fixedNavHeight - (isMobileScreen ? 50 : 0);

  return (
    <>
      <Helmet>
        <title>GoodMood</title>
        <meta
          name="description"
          content="Support independent artists and fill your space with work that inspires, entertains and resonates with you"
        />
      </Helmet>
      <Stack
        direction={{ xs: "column", md: "row-reverse" }}
        height={newHomepageFlag ? { xs: "auto", md: "60vh" } : { xs: imageHeight }}
      >
        <Link to="/shop" style={{ display: "flex", width: newHomepageFlag && !isMobileScreen ? "50%" : "100%" }}>
          <img
            src={newHomepageFlag ? (isMobileScreen ? MobileBWebp : DesktopBWebp) : isMobileScreen ? MobileWebp : DesktopWebp}
            width="100%"
            alt="living room with artwork"
            style={{
              height: newHomepageFlag ? (isMobileScreen ? "40vh" : "60vh") : imageHeight,
              top: newHomepageFlag ? 0 : `${fixedNavHeight}px`,
              position: newHomepageFlag ? "relative" : "absolute",
              objectFit: "cover",
              objectPosition: "top",
            }}
          />
        </Link>
        {/* <Veil height={windowHeight} /> */}
        <Stack
          padding={{ xs: newHomepageFlag ? "24px 16px 0" : "40px 16px", md: newHomepageFlag ? "24px 40px" : "48px 40px" }}
          width={newHomepageFlag && !isMobileScreen ? "50%" : "100%"}
          position={newHomepageFlag ? "relative" : "absolute"}
          bottom={{ xs: newHomepageFlag ? 0 : 50, md: 0 }}
          justifyContent={newHomepageFlag ? "flex-end" : "flex-start"}
        >
          <Stack
            direction={newHomepageFlag ? "column" : "row"}
            gap={2}
            alignItems={{ xs: "center", md: newHomepageFlag ? "start" : "end" }}
            justifyContent="space-between"
          >
            <Stack gap={{ xs: 2, md: 2 }} alignItems={{ xs: "stretch", md: "start" }} width="100%">
              <Typography
                fontSize={{ xs: 54, md: "6vw" }}
                lineHeight={1}
                component="h1"
                color={newHomepageFlag ? colors.black : colors.cream}
                letterSpacing={-2}
                textAlign={{ xs: "center", md: "left" }}
              >
                Unique Prints
              </Typography>
              <Stack width={{ xs: "100%", md: newHomepageFlag ? "100%" : "40%" }}>
                {isMobileScreen ? (
                  <Typography
                    fontSize={{ xs: 16, md: 18 }}
                    color={newHomepageFlag ? colors.black : colors.white}
                    align={isMobileScreen ? "center" : "left"}
                  >
                    Fill your space with art that resonates
                  </Typography>
                ) : (
                  <Typography
                    fontSize={{ xs: 16, md: 18 }}
                    color={newHomepageFlag ? colors.black : colors.white}
                    align={isMobileScreen ? "center" : "left"}
                  >
                    Support independent artists and fill your space with art that inspires, entertains and resonates
                  </Typography>
                )}
              </Stack>

              <Link to="/shop">
                <Button size="large" fullWidth={isMobileScreen} secondary={!newHomepageFlag}>
                  Shop the collection
                </Button>
              </Link>
            </Stack>

            {(!isMobileScreen || newHomepageFlag) && (
              <a href="https://www.reviews.co.uk/company-reviews/store/www.goodmoodprints.io" target="_blank" rel="noreferrer">
                <Stack
                  gap={1}
                  direction="row-reverse"
                  alignItems="center"
                  paddingBottom={showTextPadding && showCookieBanner ? 18 : 0}
                  style={{ transition: "padding-bottom 0.2s ease" }}
                >
                  <Text size={16} color={newHomepageFlag ? colors.black : colors.white} style={{ whiteSpace: "nowrap" }}>
                    Excellent · 4.9 star rating
                  </Text>
                  <Stack direction="row" gap={0.5} justifyContent={{ xs: "center", md: newHomepageFlag ? "start" : "end" }}>
                    {[
                      ...Array(5)
                        .fill("")
                        .map((r, i) => (
                          <Icon key={i} icon="star" size={16} fill={newHomepageFlag ? colors.gold : colors.white} />
                        )),
                    ]}
                  </Stack>
                </Stack>
              </a>
            )}
          </Stack>
        </Stack>
      </Stack>

      {isMobileScreen && !newHomepageFlag && (
        <Box bgcolor={colors.beige} padding={2}>
          <a href="https://www.reviews.co.uk/company-reviews/store/www.goodmoodprints.io" target="_blank" rel="noreferrer">
            <Stack alignItems="center" gap={1}>
              <Stack direction="row" gap={0.5} justifyContent={{ xs: "start", md: "end" }}>
                {[
                  ...Array(5)
                    .fill("")
                    .map((r, i) => <Icon key={i} icon="star" size={16} fill={colors.peach} />),
                ]}
              </Stack>
              <Typography fontSize={14}>Excellent · 4.9 star rating</Typography>
            </Stack>
          </a>
        </Box>
      )}

      <Stack gap={{ xs: 5, md: 8 }} paddingTop={{ xs: 5, md: 5 }} paddingBottom={{ xs: 5, md: 8 }}>
        {/* TRENDING */}
        <ScrollCarousel
          header="Trending"
          minHeight={isMobileScreen ? "85vw" : "35vw"}
          cta={{
            link: `/shop?month=${getThisMonthsDate()},${getMonthsDate(subMonths(new Date(), 1))},${getMonthsDate(
              subMonths(new Date(), 2)
            )},${getMonthsDate(subMonths(new Date(), 3))},${getMonthsDate(subMonths(new Date(), 4))}`,
            text: "Shop all",
          }}
        >
          {trending?.map((product) => <ProductCard product={product} key={`trending-${product.id}`} />) || []}
        </ScrollCarousel>

        {/* OUR PICKS */}
        <ScrollCarousel
          header={`${format(new Date(), "MMMM")}'s Picks`}
          minHeight={isMobileScreen ? "85vw" : "35vw"}
          cta={{
            link: "/shop?month=" + getThisMonthsDate(),
            text: "Shop all",
          }}
        >
          {ourPicks?.map((product) => <ProductCard product={product} key={`picks-${product.id}`} />) || []}
        </ScrollCarousel>

        {/* <ScrollCarousel
          header="Gifting Season"
          minHeight={isMobileScreen ? "60%" : "30vw"}
          cta={{
            link: "/shop",
            text: "Shop all",
          }}
          slideWidth={isMobileScreen ? "60%" : "calc(33.33% - 10.666px)"}
        >
          <ImageAndButtonWrapper>
            <Link to="/gift-guide">
              <LazyLoadImage src={GiftGuide} alt="gift guide" width="100%" height="100%" />
              <Button secondary size={isMobileScreen ? "small" : "medium"}>
                Gift guide
              </Button>
            </Link>
          </ImageAndButtonWrapper>
          <ImageAndButtonWrapper>
            <Link to="/print-sets">
              <LazyLoadImage src={PrintSets} alt="print sets" width="100%" height="100%" />
              <Button secondary size={isMobileScreen ? "small" : "medium"}>
                Save with print sets
              </Button>
            </Link>
          </ImageAndButtonWrapper>
          <ImageAndButtonWrapper>
            <Link to="/products/gift-card">
              <LazyLoadImage src={GiftCard} alt="gift card" width="100%" height="100%" />
              <Button secondary size={isMobileScreen ? "small" : "medium"}>
                This gift of choice
              </Button>
            </Link>
          </ImageAndButtonWrapper>
        </ScrollCarousel> */}

        {/* GALLERY WALLS */}
        <ScrollCarousel
          header="Gallery Walls"
          minHeight={isMobileScreen ? "60%" : "450px"}
          cta={{
            link: "/gallery-walls",
            text: "View all",
          }}
          slideWidth={isMobileScreen ? "60%" : "450px"}
        >
          {galleryWalls.map((collection) => (
            <CollectionCard key={collection.id} imageHeight={isMobileScreen ? "60vw" : "450px"} collection={collection} />
          )) || []}
        </ScrollCarousel>

        {/* PRINT SETS */}
        <ScrollCarousel
          header="Print Sets"
          minHeight={isMobileScreen ? "60%" : "450px"}
          cta={{
            link: "/print-sets",
            text: "View all",
          }}
          slideWidth={isMobileScreen ? "60%" : "450px"}
        >
          {printSets.map((printSet) => (
            <PrintSetCard
              key={printSet.id}
              printSet={{
                link: `/print-set?products=${getIdNumber(printSet.products.nodes[0].id)},${getIdNumber(
                  printSet.products.nodes[1].id
                )}`,
                product: printSet.products.nodes[0],
                complimentaryProduct: printSet.products.nodes[1],
              }}
              selectedFrame={Frame.Black}
            />
          )) || []}
        </ScrollCarousel>

        {/* COMMUNITY PICKS */}
        <ScrollCarousel
          header="Community Picks"
          minHeight={isMobileScreen ? "85vw" : "35vw"}
          cta={{
            link: "/shop/collections/community-favourites",
            text: "Shop all",
          }}
        >
          {communityPicks?.map((product) => <ProductCard product={product} key={`community-picks-${product.id}`} />) || []}
        </ScrollCarousel>

        {/* MOST POPULAR */}
        <ScrollCarousel
          header="Most Popular"
          minHeight={isMobileScreen ? "85vw" : "35vw"}
          cta={{
            link: "/shop",
            text: "Shop all",
          }}
        >
          {mostPopular?.map((product) => <ProductCard product={product} key={`popular-${product.id}`} />) || []}
        </ScrollCarousel>
      </Stack>

      {!isMobileScreen && (
        <Box
          paddingX={{ xs: 2, md: 5 }}
          paddingY={{ xs: 5, md: 8 }}
          width="100%"
          sx={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover" }}
        >
          <Box border={`3px solid ${colors.white}`}>
            <Box bgcolor={colors.beige}>
              <Stack direction={{ xs: "column-reverse", md: "row" }}>
                <Stack
                  gap={2}
                  width={{ xs: "100%", md: "50%" }}
                  alignItems="start"
                  justifyContent="end"
                  padding={{ xs: 3, md: 5 }}
                >
                  <Typography variant="h2" fontSize={{ xs: 32, md: 48 }}>
                    Gallery Wall Builder
                  </Typography>
                  <Typography>Visualise pieces in your own space, or mix and match with ours</Typography>

                  <Button secondary onClick={toggleGalleryWallBuilderIsOpen}>
                    Start building
                  </Button>
                </Stack>
                <Stack width={{ xs: "100%", md: "50%" }}>
                  <WebpImage src={wallBuilderBanner} webp={wallBuilderBannerWebp} alt="gallery wall interior" />
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* THE ARTISTS */}
      <Box bgcolor={colors.white} paddingTop={{ xs: 0, md: 8 }} width="100%">
        <ScrollCarousel
          header="Artists"
          minHeight={isMobileScreen ? "95%" : "450px"}
          cta={{
            link: "/artists",
            text: "View all",
          }}
          slideWidth={isMobileScreen ? "95%" : "450px"}
        >
          {artists?.map((artist) => (
            <div key={artist.id} style={{ height: "100%" }}>
              <Link to={`/artists/${artist.permalink || artist.id}`}>
                <Box height={{ xs: 300, md: 500 }} overflow="hidden" position="relative">
                  <LazyLoadImage
                    src={artist.image || placeholder}
                    alt={artist.firstName}
                    width="100%"
                    height="100%"
                    style={{ objectFit: "cover" }}
                  />
                  <ArtistButton>
                    <Button secondary>
                      <>
                        {artist.firstName} {artist.lastName}
                      </>
                    </Button>
                  </ArtistButton>
                </Box>
              </Link>
            </div>
          )) || []}
        </ScrollCarousel>
      </Box>

      {/* SHOP BY */}
      {isMobileScreen ? (
        <Box paddingY={5}>
          <ScrollCarousel
            header="Shop By"
            cta={{
              link: "/shop",
              text: "Shop all",
            }}
            slideWidth="95%"
          >
            <ImageAndButtonWrapper key="photography">
              <Link
                to={{
                  pathname: "/shop",
                  search: "?medium=photography",
                }}
              >
                <Image src={photography} alt="photography" />
                <Button secondary size="medium">
                  Photography
                </Button>
              </Link>
            </ImageAndButtonWrapper>

            <ImageAndButtonWrapper key="painting">
              <Link
                to={{
                  pathname: "/shop",
                  search: "?medium=painting",
                }}
              >
                <Image src={painting} alt="painting" />
                <Button secondary size="medium">
                  Painting
                </Button>
              </Link>
            </ImageAndButtonWrapper>

            <ImageAndButtonWrapper key="graphic">
              <Link
                to={{
                  pathname: "/shop",
                  search: "?medium=graphic_design",
                }}
              >
                <Image src={graphic} alt="graphic" />
                <Button secondary size="medium">
                  Graphic Design
                </Button>
              </Link>
            </ImageAndButtonWrapper>

            <ImageAndButtonWrapper key="mixed">
              <Link
                to={{
                  pathname: "/shop",
                  search: "?medium=mixed_media",
                }}
              >
                <Image src={mixed} alt="mixed" />
                <Button secondary size="medium">
                  Mixed Media
                </Button>
              </Link>
            </ImageAndButtonWrapper>
          </ScrollCarousel>
        </Box>
      ) : (
        <Box paddingX={5} paddingY={8} width="100%">
          <Stack gap={4}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Header type="h2">Shop By</Header>
              <Link to="/shop">
                <Button secondary>Shop all</Button>
              </Link>
            </Stack>

            <Stack gap={2} direction="row">
              <ImageAndButtonWrapper>
                <Link
                  to={{
                    pathname: "/shop",
                    search: "?medium=photography",
                  }}
                >
                  <Image src={photography} alt="photography" />
                  <Button secondary>Photography</Button>
                </Link>
              </ImageAndButtonWrapper>

              <ImageAndButtonWrapper>
                <Link
                  to={{
                    pathname: "/shop",
                    search: "?medium=mixed_media",
                  }}
                >
                  <Image src={mixed} alt="mixed" />
                  <Button secondary>Mixed Media</Button>
                </Link>
              </ImageAndButtonWrapper>

              <ImageAndButtonWrapper>
                <Link
                  to={{
                    pathname: "/shop",
                    search: "?medium=graphic_design",
                  }}
                >
                  <Image src={graphic} alt="graphic" />
                  <Button secondary>Graphic Design</Button>
                </Link>
              </ImageAndButtonWrapper>

              <ImageAndButtonWrapper key="painting">
                <Link
                  to={{
                    pathname: "/shop",
                    search: "?medium=painting",
                  }}
                >
                  <Image src={painting} alt="painting" />
                  <Button secondary size="medium">
                    Painting
                  </Button>
                </Link>
              </ImageAndButtonWrapper>
            </Stack>
          </Stack>
        </Box>
      )}

      <Box bgcolor={colors.beige} paddingX={{ xs: 2, md: 5 }} paddingY={{ xs: 5, md: 8 }} width="100%">
        <Box paddingBottom={4}>
          <Header type="h2">How It Works</Header>
        </Box>
        <Stack gap={3} rowGap={6} direction={{ xs: "column", md: "row" }}>
          <Stack width={{ xs: "100%", md: "50%" }}>
            <WebpImage src={How} webp={HowWebp} alt="create" style={{ width: "100%", height: "auto" }} />
            <Header type="h3" margin="24px 0 12px">
              Artists create and upload, risk free
            </Header>
            <Text size={18} margin="0 0 16px">
              Artists upload their work, set their price and we take care of the rest. Printing, packing, sending – we’ve got it.
              There's no costs and no complications to uploading your work, so you can share as much as you want.
            </Text>
            <Link to="/upload">
              <Button secondary>Upload and sell</Button>
            </Link>
          </Stack>
          <Stack width={{ xs: "100%", md: "50%" }}>
            <WebpImage src={How2} webp={How2Webp} alt="size" style={{ width: "100%", height: "auto" }} />
            <Header type="h3" margin="24px 0 12px">
              We launch monthly collections
            </Header>
            <Text size={18} margin="0 0 16px">
              We review every submission and showcase our favourite new pieces on our homepage, in monthly collections – think of
              them as rolling pop-up exhibitions on our platform, which keeps the site fresh for our audience.
            </Text>
            <Link to="/shop">
              <Button secondary>Shop now</Button>
            </Link>
          </Stack>
        </Stack>
      </Box>
      <RollingTextBanner
        text="zero effort · experiment more · risk free · waste nothing · get paid · "
        background={colors.purple}
        color={colors.black}
        padding={12}
        size={isMobileScreen ? 12 : 16}
      />
    </>
  );
};

export default Home;
