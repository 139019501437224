import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Stack, Typography } from "@mui/material";
import AutoResizeText from "../../components/AutoResizeText";
import Button from "../../components/Button";
import FrameBadges from "../../components/FrameBadges";
import { Frame } from "../../types/product";
import ArtQuiz from "./ArtQuiz";
import usePrintSets from "./usePrintSets";
import { useAppState } from "../../state";
import Switch from "../../components/Switch";
import PrintSetGrid from "./PrintSetGrid";

const PrintSets = () => {
  const {
    selectedMediums,
    setSelectedMediums,
    selectedStyles,
    setSelectedStyles,
    producePersonalisedPrintSets,
    mediumImagery,
    styleImagery,
    printSets,
    personalisedPrintSets,
    fetchMore,
    hasMore,
    loading,
    fetchMorePersonalised,
    hasMorePersonalised,
    loadingPersonalised,
  } = usePrintSets();
  const [searchParams, setSearchParams] = useSearchParams();
  const artQuizParam = searchParams.get("art-quiz");
  const { isMobileScreen } = useAppState();
  const [selectedTab, setSelectedTab] = useState("default");
  const [showArtQuiz, setShowArtQuiz] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState(Frame.Black);

  const openArtQuiz = () => {
    setShowArtQuiz(true);
    window.scrollTo(0, 0);
  };

  const closeArtQuiz = () => {
    setShowArtQuiz(false);
    searchParams.delete("art-quiz");
    setSearchParams(searchParams);
  };

  const goToArtQuiz = () => {
    setSearchParams({ "art-quiz": "true" });
  };

  useEffect(() => {
    if (artQuizParam === "true") {
      openArtQuiz();
    } else {
      closeArtQuiz();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artQuizParam]);

  return (
    <>
      <Helmet>
        <title>Print Sets | GoodMood</title>
        <meta
          name="description"
          content="Transform your space with our carefully curated print sets. Personalise them with your preferences and make them uniquely yours"
        />
      </Helmet>
      <Stack gap={{ xs: 3, md: 4 }} paddingTop={2} paddingBottom={{ xs: 3, md: 5 }} position="relative">
        <Stack gap={{ xs: 1, md: 2 }} paddingX={{ xs: 2, md: 5 }} alignItems="center" width="100%">
          <AutoResizeText text="Print sets" align="center" maxSize={isMobileScreen ? 50 : 85} />
          <Typography width={{ xs: "100%", md: "50%" }} align="center" fontSize={{ xs: 14, md: 20 }}>
            Transform your space with our carefully curated print sets. Personalise them with your preferences and make them
            uniquely yours
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" paddingX={{ xs: 2, md: 5 }}>
          <Button secondary onClick={goToArtQuiz}>
            Personalise ✨
          </Button>
          {!isMobileScreen && personalisedPrintSets && (
            <Switch
              options={[
                { label: "Default", value: "default" },
                { label: "My Sets", value: "personalised" },
              ]}
              onChange={(value) => setSelectedTab(value)}
              selected={selectedTab}
            />
          )}
          <FrameBadges selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} size="medium" />
        </Stack>

        {isMobileScreen && personalisedPrintSets && (
          <Stack direction="row" alignItems="center" paddingX={{ xs: 2, md: 5 }}>
            <Switch
              fullWidth
              options={[
                { label: "Default", value: "default" },
                { label: "My Sets", value: "personalised" },
              ]}
              onChange={(value) => setSelectedTab(value)}
              selected={selectedTab}
            />
          </Stack>
        )}

        {showArtQuiz && (
          <ArtQuiz
            selectedMediums={selectedMediums}
            setSelectedMediums={setSelectedMediums}
            selectedStyles={selectedStyles}
            setSelectedStyles={setSelectedStyles}
            mediumImagery={mediumImagery}
            styleImagery={styleImagery}
            producePersonalisedPrintSets={producePersonalisedPrintSets}
            onClose={closeArtQuiz}
            setSelectedTab={setSelectedTab}
          />
        )}

        {selectedTab === "default" && (
          <>
            <PrintSetGrid
              printSets={printSets}
              selectedFrame={selectedFrame}
              loading={loading}
              fetchMore={fetchMore}
              hasMore={hasMore}
            />
            <Stack paddingX={{ xs: 2, md: 5 }} alignItems="center">
              <Button secondary onClick={goToArtQuiz}>
                Create your own ✨
              </Button>
            </Stack>
          </>
        )}

        {selectedTab === "personalised" && personalisedPrintSets && (
          <PrintSetGrid
            printSets={personalisedPrintSets}
            selectedFrame={selectedFrame}
            loading={loadingPersonalised}
            fetchMore={fetchMorePersonalised}
            hasMore={hasMorePersonalised}
          />
        )}
      </Stack>
    </>
  );
};

export default PrintSets;
