import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import { last12Months } from "../../../../state/constants";
import useFilterOptions from "../useFilterOptions";
import { colors } from "../../../../theme";
import CheckboxGroup from "../CheckboxGroup";
import Button from "../../../../components/Button";
import { SetURLSearchParams } from "react-router-dom";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  filterCount: number;
  searchParams: URLSearchParams;
  setSearchParams: SetURLSearchParams;
  filterGroupsToHide?: string[];
  availableTags?: string[];
};

const FiltersDesktopDrawer: React.FC<Props> = ({
  isOpen,
  onClose,
  filterCount,
  searchParams,
  setSearchParams,
  filterGroupsToHide,
  availableTags,
}) => {
  const state = searchParams.toString() ? Object.fromEntries(searchParams) : {};
  const { mediumOptions, styleOptions, subjectOptions, sizeOptions, orientationOptions } = useFilterOptions();

  const getFilterCount = (name: string) => {
    const count = state[name] ? state[name].split(",").length : 0;
    return count > 0 ? `(${count})` : "";
  };

  const clearFilters = () => {
    const newParams = new URLSearchParams();
    setSearchParams(newParams, { preventScrollReset: true });
    if (window.scrollY > 650) window.scrollTo(0, 650);
  };

  const filterGroups = [
    {
      name: "medium",
      label: "Medium",
      options: mediumOptions,
    },
    {
      name: "style",
      label: "Style",
      options: availableTags ? styleOptions.filter((option) => availableTags.includes(option.value)) : styleOptions,
    },
    {
      name: "subject",
      label: "Subject",
      options: availableTags ? subjectOptions.filter((option) => availableTags.includes(option.value)) : subjectOptions,
    },
    {
      name: "size",
      label: "Size",
      options: sizeOptions,
    },
    {
      name: "orientation",
      label: "Orientation",
      options: orientationOptions,
    },
    {
      name: "month",
      label: "Month collection",
      options: last12Months,
    },
  ];

  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <Box width={480}>
        <Stack
          paddingX={3}
          paddingY={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`1px solid ${colors.grey10}`}
        >
          <Stack direction="row" gap={1} alignItems="center">
            <Typography>Filters {filterCount > 0 ? `(${filterCount})` : ""}</Typography>
            {filterCount > 0 && (
              <Button tertiary onClick={clearFilters}>
                Clear
              </Button>
            )}
          </Stack>
          <IconButton onClick={onClose} sx={{ backgroundColor: colors.grey02 }}>
            <CloseRounded color="primary" />
          </IconButton>
        </Stack>

        <Stack gap={5} padding={3}>
          {filterGroups
            .filter((group) => !filterGroupsToHide?.includes(group.name))
            .map((group) => (
              <Stack key={group.name} gap={1}>
                <Typography>
                  {group.label} {getFilterCount(group.name)}
                </Typography>
                <CheckboxGroup name={group.name} options={group.options} state={state} setState={setSearchParams} />
              </Stack>
            ))}
        </Stack>
      </Box>
    </Drawer>
  );
};

export default FiltersDesktopDrawer;
