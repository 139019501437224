import { useCallback, useEffect, useMemo, useState } from "react";
import { format } from "date-fns";
import { Dictionary, sortBy } from "lodash";
import { v4 as uuid } from "uuid";
import { match } from "ts-pattern";
import { CircularProgress, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { DateRange, DateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getUserById } from "../../../../services/Firebase";
import { formattedPrice } from "../../../../helpers/money";
import { getBlackFridayCommissionProtection, getOrderItemTotals } from "../../../../helpers/prices";
import Button from "../../../../components/Button";
import { InvoiceStatus } from "../../../../types/invoice";
import { createOrUpdateInvoice } from "../../../../services/Firebase/invoices";
import { createDraftInvoices } from "../../helpers/invoices";
import { AdminOrderLineItemFragment, useGetAdminOrdersQuery } from "../../../../generated/graphql";
import Switch from "../../../../components/Switch";
import { CurrencyCode } from "../../../../generated/storefront";
import { getAmountsInLocalCurrencies, getLocalAmountInGbp, getPaypalBalances } from "../../../../services/API/paypal";
import { colors, fonts } from "../../../../theme";
import TextLink from "../../../../components/TextLink";
import MonthPicker from "../../../../components/MonthPicker";
import { dataGridStyles } from "../../styles";

export type Row = {
  id: string;
  artist: string;
  paypalEmail: string;
  totalOrders: number;
  totalSalePrice: string;
  totalDiscountedPrice: string;
  totalDiscountedAmount: number;
  commission: string;
  commissionAmount: number;
  blackFridayCommissionProtection: number;
  currency?: CurrencyCode;
  vatNumber: string;
  paymentMethod: "AUTO" | "MANUAL" | "BANK";
};

type DateSelector = "month" | "range";

type Props = {
  selectedTimeRange: "month" | "range";
  setSelectedTimeRange: (value: "month" | "range") => void;
  month: Date;
  setMonth: (value: Date) => void;
  dateRange: DateRange<string>;
  setDateRange: (value: DateRange<string>) => void;
  timeRangeQuery: string;
  goToPreviousMonth: () => void;
  goToNextMonth: () => void;
};

const Payments = ({
  selectedTimeRange,
  setSelectedTimeRange,
  month,
  setMonth,
  dateRange,
  setDateRange,
  timeRangeQuery,
  goToPreviousMonth,
  goToNextMonth,
}: Props) => {
  const { data, loading, fetchMore } = useGetAdminOrdersQuery({
    variables: {
      query: timeRangeQuery,
    },
  });
  const hasMoreOrders = data?.orders?.pageInfo?.hasNextPage;
  const afterCursor = data?.orders?.pageInfo?.endCursor;
  const loadingOrders = hasMoreOrders || loading;
  const [loadingRows, setLoadingRows] = useState(false);
  const orders = useMemo(() => data?.orders?.nodes || [], [data]);

  const [rows, setRows] = useState<Row[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [balances, setBalances] = useState<{ currency: CurrencyCode; total: string }[]>();
  const [currencyTotals, setCurrencyTotals] = useState<{ currency: CurrencyCode; gbpAmount: number; localAmount: number }[]>();
  const [gbpAmountToTransferToPaypal, setGbpAmountToTransferToPaypal] = useState(0);
  const totalDiscountedPrice = rows.reduce((total, row) => total + row.totalDiscountedAmount, 0);
  const totalCommission = rows.reduce((total, row) => total + row.commissionAmount, 0);

  const totalCommissionsInCurrency = rows.reduce((total, row) => {
    if (row.currency) {
      total[row.currency] = (total[row.currency] || 0) + row.commissionAmount;
    } else {
      total[CurrencyCode.Gbp] = (total[CurrencyCode.Gbp] || 0) + row.commissionAmount;
    }
    return total;
  }, {} as Dictionary<number>);

  const totalCommissionInCurrencyPossibleToPay = Object.entries(totalCommissionsInCurrency).reduce(
    (total, [currency, amount]) => {
      const balance = balances?.find((b) => b.currency === currency);
      if (balance && Number(balance.total) > 0) {
        total[currency] = (total[currency] || 0) + amount;
      } else {
        total[CurrencyCode.Gbp] = (total[CurrencyCode.Gbp] || 0) + amount;
      }
      return total;
    },
    {} as Dictionary<number>
  );

  const flagEmojis = {
    GBP: "🇬🇧",
    EUR: "🇪🇺",
    USD: "🇺🇸",
    AUD: "🇦🇺",
  } as any;

  const orderedCurrencies = ["GBP", "EUR", "USD", "AUD"];

  const columns: GridColDef[] = [
    { field: "artist", headerName: "Artist", minWidth: 150 },
    { field: "email", headerName: "Email", minWidth: 200 },
    { field: "paypalEmail", headerName: "Paypal Email", minWidth: 200 },
    { field: "totalOrders", headerName: "Total Orders", align: "center" },
    { field: "totalSalePrice", headerName: "Total Sale Price", align: "right" },
    { field: "totalDiscountedPrice", headerName: "Total Discounted Price", align: "right" },
    { field: "commission", headerName: "Commission", align: "right" },
    { field: "currency", headerName: "Currency", align: "center" },
    { field: "vatNumber", headerName: "VAT/GST", minWidth: 150 },
    {
      field: "generateInvoice",
      headerName: "Invoice",
      renderCell: (params) => (
        <TextLink
          onClick={() =>
            createOrUpdateInvoice({
              artist: params.row.artist,
              artistId: params.row.id,
              paypalEmail: params.row.paypalEmail,
              amount: params.row.commissionAmount,
              vatNumber: params.row.vatNumber,
              date: new Date(month),
              status: InvoiceStatus.DRAFT,
              items:
                params.row.blackFridayCommissionProtection > 0
                  ? [
                      {
                        id: uuid(),
                        description: "Black Friday commission protection",
                        amount: params.row.blackFridayCommissionProtection,
                        custom: true,
                      },
                    ]
                  : [],
            })
          }
        >
          <Typography fontSize={14} fontFamily={fonts.body}>
            Create draft
          </Typography>
        </TextLink>
      ),
    },
  ];

  const fetchPaypalBalances = async () => {
    const { balances } = await getPaypalBalances();
    setBalances(balances.map((b) => ({ currency: b.currency, total: b.total_balance.value })));
  };

  const getRows = async (
    ordersByVendor: Dictionary<{
      items: (AdminOrderLineItemFragment & { orderCreatedAt: string })[];
    }>
  ) => {
    setLoadingRows(true);
    const rowPromises = Object.entries(ordersByVendor).map(async ([vendorId, orders]) => {
      const { items } = orders;
      const artist = vendorId ? await getUserById(vendorId) : null;
      if (!artist) {
        console.log("No artist found for order", orders);
      }
      const { orderTotal, discountTotal, commissionTotal } = getOrderItemTotals(items);
      const blackFridayCommissionProtection = getBlackFridayCommissionProtection(items);
      const discountedTotal = orderTotal - discountTotal;
      return {
        id: vendorId,
        artist: artist ? `${artist.firstName} ${artist.lastName}` : "GoodMood",
        email: artist ? `${artist.email}` : "GoodMood",
        paypalEmail: artist ? `${artist.paypalEmail}` : "GoodMood",
        totalOrders: items.reduce((total, item) => total + item.quantity, 0),
        totalSalePrice: formattedPrice(orderTotal, CurrencyCode.Gbp),
        totalDiscountedPrice: formattedPrice(discountedTotal, CurrencyCode.Gbp),
        totalDiscountedAmount: discountedTotal,
        commission: formattedPrice(commissionTotal, CurrencyCode.Gbp),
        commissionAmount: commissionTotal,
        blackFridayCommissionProtection,
        currency: artist?.currency,
        vatNumber: artist?.vatRegistered?.vatNumber || "",
        paymentMethod: artist?.payByBank
          ? ("BANK" as const)
          : commissionTotal < (artist?.currency === "GBP" ? 40 : 150)
          ? ("AUTO" as const)
          : ("MANUAL" as const),
      };
    });

    const rowsToBeSet = await Promise.all(rowPromises);
    setRows(sortBy(rowsToBeSet, (r) => -r.commissionAmount));
    setLoadingRows(false);
  };

  const getOrderItems = useCallback(async () => {
    const ordersByVendor = orders.reduce(
      (
        result: Dictionary<{
          items: (AdminOrderLineItemFragment & { orderCreatedAt: string })[];
        }>,
        order
      ) => {
        order.lineItems.nodes.forEach((item) => {
          if (result[item.vendor]) {
            result[item.vendor].items = [...result[item.vendor].items, { ...item, orderCreatedAt: order.createdAt }];
          } else {
            result[item.vendor] = {
              items: [{ ...item, orderCreatedAt: order.createdAt }],
            };
          }
        });
        return result;
      },
      {}
    );
    getRows(ordersByVendor);
  }, [orders]);

  const getAutoPaymentTotalsForEachCurrency = async (rows: Row[]) => {
    const totals: Dictionary<number> = {};
    rows.forEach((row) => {
      const currency = row.currency || CurrencyCode.Gbp;
      if (row.paymentMethod === "MANUAL" || row.paymentMethod === "BANK") return;
      if (totals[currency]) {
        totals[currency] += row.commissionAmount;
      } else {
        totals[currency] = row.commissionAmount;
      }
    });

    const totalsArray = Object.entries(totals).map(([currency, amount]) => ({
      currency: currency as CurrencyCode,
      amount,
    }));

    const totalsInCurrency = await getAmountsInLocalCurrencies(totalsArray);
    setCurrencyTotals(totalsInCurrency);
  };

  const getUsableBalances = useCallback(() => {
    if (!balances || !currencyTotals) return;
    return Promise.all(
      balances?.map(async (b) => {
        const balance = Number(b.total);
        const currency = b.currency;
        const totalInCurrency = currencyTotals?.find((c) => c.currency === currency);

        if (currency !== CurrencyCode.Gbp && totalInCurrency?.localAmount && balance > 0) {
          if (totalInCurrency.localAmount <= balance) {
            return {
              currency,
              amount: totalInCurrency.localAmount,
              amountInGbp: totalInCurrency.gbpAmount,
            };
          } else {
            const amountInGbp = await getLocalAmountInGbp({ amount: balance, currency });
            return {
              currency,
              amount: balance,
              amountInGbp,
            };
          }
        }

        return {
          currency,
          amount: 0,
          amountInGbp: 0,
        };
      }) || []
    );
  }, [balances, currencyTotals]);

  const manualAndBankCommissionTotal = useMemo(() => {
    return rows.reduce((total, row) => {
      if (row.paymentMethod === "MANUAL" || row.paymentMethod === "BANK") {
        total += row.commissionAmount;
      }
      return total;
    }, 0);
  }, [rows]);

  const calculateGbpAmountToTransferToPaypal = useCallback(async () => {
    const usableBalances = await getUsableBalances();
    if (!usableBalances) return;
    const gbpBalance = Number(balances?.find((b) => b.currency === CurrencyCode.Gbp)?.total || 0);
    const usableBalancesTotalInGbp = usableBalances.reduce((total, balance) => total + balance.amountInGbp, 0);
    setGbpAmountToTransferToPaypal(totalCommission - manualAndBankCommissionTotal - gbpBalance - usableBalancesTotalInGbp);
  }, [balances, totalCommission, manualAndBankCommissionTotal, getUsableBalances]);

  const createInvoices = async () => {
    setLoadingInvoices(true);
    await createDraftInvoices(rows, format(month, "yyyy-MM-dd"));
    setLoadingInvoices(false);
  };

  useEffect(() => {
    if (rows.length) {
      getAutoPaymentTotalsForEachCurrency(rows);
    }
  }, [rows]);

  useEffect(() => {
    if (!balances) {
      fetchPaypalBalances();
    }
  }, [balances]);

  useEffect(() => {
    if (!loadingOrders) {
      getOrderItems();
    }
  }, [loadingOrders, getOrderItems]);

  useEffect(() => {
    if (hasMoreOrders && afterCursor) {
      fetchMore({
        variables: {
          query: timeRangeQuery,
          afterCursor,
        },
      });
    }
  }, [afterCursor, fetchMore, hasMoreOrders, timeRangeQuery]);

  useEffect(() => {
    calculateGbpAmountToTransferToPaypal();
  }, [calculateGbpAmountToTransferToPaypal]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box paddingBottom={2}>
        <Stack gap={2}>
          <Stack gap={2} direction="row" alignItems="center" justifyContent="space-between">
            <Stack gap={2} direction="row" alignItems="center">
              <Switch
                options={[
                  { label: "Month", value: "month" },
                  { label: "Range", value: "range" },
                ]}
                selected={selectedTimeRange}
                onChange={(value) => setSelectedTimeRange(value as DateSelector)}
              />
              {match(selectedTimeRange)
                .with("month", () => (
                  <MonthPicker
                    month={month}
                    setMonth={setMonth}
                    loading={loadingOrders}
                    goToPreviousMonth={goToPreviousMonth}
                    goToNextMonth={goToNextMonth}
                  />
                ))
                .with("range", () => (
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateRangePicker
                      value={dateRange}
                      onChange={setDateRange}
                      localeText={{ start: "Start", end: "End" }}
                      disableFuture
                    />
                  </LocalizationProvider>
                ))
                .exhaustive()}
            </Stack>
            <Button size="medium" secondary loading={loadingInvoices} onClick={createInvoices}>
              Create Invoices
            </Button>
          </Stack>

          <Stack gap={2} width="100%" direction="row">
            <Stack
              gap={1}
              justifyContent="space-between"
              minHeight={{ xs: 100, md: 120 }}
              border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
              bgcolor={{ xs: colors.cardGrey, md: colors.white }}
              borderRadius={4}
              padding={2}
              flex={1}
            >
              <Typography fontSize={{ xs: 12, md: 16 }}>Total Orders</Typography>
              {loadingOrders || loadingRows ? (
                <Stack width={20}>
                  <CircularProgress size="small" />
                </Stack>
              ) : (
                <Typography fontSize={{ xs: 20, md: 32 }} fontWeight={600} fontFamily={fonts.banner} letterSpacing={-1}>
                  {orders.length}
                </Typography>
              )}
            </Stack>

            <Stack
              gap={1}
              justifyContent="space-between"
              minHeight={{ xs: 100, md: 120 }}
              border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
              bgcolor={{ xs: colors.cardGrey, md: colors.white }}
              borderRadius={4}
              padding={2}
              flex={1}
            >
              <Typography fontSize={{ xs: 12, md: 16 }}>Total Sales</Typography>
              {loadingOrders || loadingRows ? (
                <Stack width={20}>
                  <CircularProgress size="small" />
                </Stack>
              ) : (
                <Typography fontSize={{ xs: 20, md: 32 }} fontWeight={600} fontFamily={fonts.banner} letterSpacing={-1}>
                  {formattedPrice(totalDiscountedPrice, CurrencyCode.Gbp)}
                </Typography>
              )}
            </Stack>

            <Stack
              gap={1}
              justifyContent="space-between"
              minHeight={{ xs: 100, md: 120 }}
              border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
              bgcolor={{ xs: colors.cardGrey, md: colors.white }}
              borderRadius={4}
              padding={2}
              flex={1}
            >
              <Typography fontSize={{ xs: 12, md: 16 }}>Total Commission</Typography>
              {loadingOrders || loadingRows ? (
                <Stack width={20}>
                  <CircularProgress size="small" />
                </Stack>
              ) : (
                <Stack gap={0.2}>
                  {totalCommissionInCurrencyPossibleToPay &&
                    orderedCurrencies.map((currency) => (
                      <Typography fontSize={14}>
                        {`${flagEmojis[currency] ? flagEmojis[currency] : currency}`}{" "}
                        {formattedPrice(totalCommissionInCurrencyPossibleToPay[currency] || 0, CurrencyCode.Gbp)}
                      </Typography>
                    ))}
                </Stack>
              )}
              {loadingOrders || loadingRows ? (
                <Stack width={20}>
                  <CircularProgress size="small" />
                </Stack>
              ) : (
                <Typography fontSize={{ xs: 20, md: 32 }} fontWeight={600} fontFamily={fonts.banner} letterSpacing={-1}>
                  {formattedPrice(totalCommission, CurrencyCode.Gbp)}
                </Typography>
              )}
            </Stack>

            <Stack
              gap={1}
              justifyContent="space-between"
              minHeight={{ xs: 100, md: 120 }}
              border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
              bgcolor={{ xs: colors.cardGrey, md: colors.white }}
              borderRadius={4}
              padding={2}
              flex={1}
            >
              <Typography fontSize={{ xs: 12, md: 16 }}>Paypal Balances</Typography>
              <Stack gap={0.2}>
                {sortBy(balances, (b) => orderedCurrencies.indexOf(b.currency)).map(
                  (b) =>
                    Number(b.total) > 0 && (
                      <Typography key={b.currency} fontSize={14}>
                        {`${flagEmojis[b.currency] ? flagEmojis[b.currency] : b.currency}`} {formattedPrice(b.total, b.currency)}
                      </Typography>
                    )
                )}
              </Stack>
            </Stack>

            <Stack
              gap={1}
              justifyContent="space-between"
              minHeight={{ xs: 100, md: 120 }}
              border={{ xs: 0, md: `1px solid ${colors.grey10}` }}
              bgcolor={{ xs: colors.cardGrey, md: colors.white }}
              borderRadius={4}
              padding={2}
              flex={1}
            >
              <Typography fontSize={{ xs: 12, md: 16 }}>Amount to transfer</Typography>
              <Typography fontSize={14}>
                {flagEmojis.GBP} {formattedPrice(gbpAmountToTransferToPaypal, CurrencyCode.Gbp)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <DataGrid
        style={{ height: loadingOrders || loadingRows ? "70vh" : "auto", minHeight: "70vh" }}
        loading={loadingOrders}
        rows={rows}
        columns={columns}
        pageSizeOptions={[]}
        sx={dataGridStyles}
      />
    </Box>
  );
};

export default Payments;
